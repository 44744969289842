export enum CC_TAGS {
  O_65 = 'o_65',
  U_65 = 'u_65',
  MED_OPTIONS = 'med_options',
  COUGH_ONLY = 'cough_only',
  COUGH_PLUS = ' cough_plus',
  COUGH_RED_FLAG = 'cough_red_flag',
  COUGH_ONLY_MED = 'cough_only_med',
  COUGH_PLUS_MED = 'cough_plus_med',
  DIABETIC = 'diabetic',
  BLOCKED_NOSE_MED = 'blocked_nose_med',
  DRY_COUGH_ONLY_MED = 'dry_cough_only_med',
  DRY_MUCUS_COUGH_PLUS_MED = 'dry_mucus_cough_plus_med',
  DRY_COUGH_PLUS_MED = 'dry_cough_plus_med',
  MUCUS_COUGH_PLUS_MED = 'mucus_cough_plus_med',
  WANTS_DROWSY = 'wants_drowsy',
  DRY_MUCUS_COUGH_ONLY_MED = 'dry_mucus_cough_only_med',
  COUGH_ONLY_NONMED = 'cough_only_nonmed',
  COUGH_OTHER_SYMP_NONMED = 'cough_other_symp_nonmed',
  BLOCKED_NOSE_NONMED = 'blocked_nose_nonmed',
  SORE_THROAT_NONMED = 'sore_throat_nonmed',
  BLOCKED_NOSE = 'blocked_nose',
  SORE_THROAT = 'sore_throat',
  NON_MED_OPTIONS = 'non_med_options',
  SORE_THROAT_MED = 'sore_throat_med',
  AVOID_ACETAMINOPHEN = 'avoid_acetaminophen',
  DRY_COUGH = 'dry_cough',
  MUCUS_COUGH = 'mucus_cough',
  DRY_MUCUS_COUGH = 'dry_mucus_cough',
  DRY_COUGH_ONLY = 'dry_cough_only',
  MUCUS_COUGH_ONLY = 'mucus_cough_only',
  DRY_MUCUS_COUGH_ONLY = 'dry_mucus_cough_only',
  DRY_COUGH_PLUS = 'dry_cough_plus',
  MUCUS_COUGH_PLUS = 'mucus_cough_plus',
  DRY_MUCUS_COUGH_PLUS = 'dry_mucus_cough_plus',
  WANTS_PAIN_RELIEF = 'wants_pain_relief',
  AVOID_PAIN_RELIEF = 'avoid_pain_relief',
  AVOID_DROWSY = 'avoid_drowsy',
  MUCUS_COUGH_ONLY_MED = 'mucus_cough_only_med',
  DRY_COUGH_PLUS_MED_AVOID_ACETAMINOPHEN = 'dry_cough_plus_med_avoid_acetaminophen',
  DRY_COUGH_PLUS_MED_AVOID_PAIN_RELIEF = 'dry_cough_plus_med_avoid_pain_relief',
  MUCUS_COUGH_PLUS_MED_AVOID_ACETAMINOPHEN = 'mucus_cough_plus_med_avoid_acetaminophen',
  MUCUS_COUGH_PLUS_MED_AVOID_PAIN_RELIEF = 'mucus_cough_plus_med_avoid_pain_relief',
  WANT_TABLET = 'want_tablet',
  WANT_SYRUP = 'want_syrup',
  COUGH_DROP = 'cough_drop',
  SALINE_NASAL_SPRAY = 'saline_nasal_spray',
  VAPOR_INHALER = 'vapor_inhaler',
  VAPOR_STEAM_LIQUID = 'vapor_steam_liquid',
  NASAL_STRIPS = 'nasal_strips',
  NASAL_WASH_KIT = 'nasal_wash_kit',
  VAPORIZER = 'vaporizer',
  VAPOR_RUB = 'vapor_rub',
  ZINC = 'zinc',
  VIT_C = 'vit_c',
  ELDERBERRY = 'elderberry',
  MULTI_VIT = 'multi_vit',
}

export default CC_TAGS;
