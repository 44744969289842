export const RECOMMENDATIONS_ACTIONS = {
  ADD_TAGS: 'ADD_TAGS',
  SET_TAGS: 'SET_TAGS',
  REMOVE_TAGS: 'REMOVE_TAGS',
  ADD_EXTRA_TAGS: 'ADD_EXTRA_TAGS',
  REMOVE_EXTRA_TAGS: 'REMOVE_EXTRA_TAGS',
  SET_EXTRA_TAGS: 'SET_EXTRA_TAGS',
  SET_PROGRESS: 'SET_PROGRESS',
  ADD_PROGRESS: 'ADD_PROGRESS',
  POP_FROM_PROGRESS_HISTORY: 'POP_FROM_PROGRESS_HISTORY',
  RESET_PROGRESS: 'RESET_PROGRESS',
  SET_RECOMMENDATIONS: 'SET_RECOMMENDATIONS',
};

export const USER_ACTIONS = {
  SET_ID: 'SET_ID',
};

export const QUIZ_ACTIONS = {
  SET_QUESTIONS_HISTORY: 'SET_QUESTIONS_HISTORY',
  SET_ACTIVE_QUESTION: 'SET_ACTIVE_QUESTION',
  ADD_QUESTION_TO_HISTORY: 'ADD_QUESTION_TO_HISTORY',
  POP_QUESTION_FROM_HISTORY: 'POP_QUESTION_FROM_HISTORY',
  RESET_QUIZ: 'RESET_QUIZ',
};
