interface HomeContent {
  [key: string]: {
    title: string;
    subTitle: string;
    agreement: string;
    term: string;
    tool_tip_1: string;
    tool_tip_2: string;
    tool_tip_3: string;
    tool_tip_4: string;
  };
}

export const contentData: HomeContent = {
  VITS: {
    title: 'Welcome to the Vitamin & Supplement Selector Tool*',
    subTitle: 'We’re here to help you find products that are best suited to your needs',
    agreement: 'by continuing you are agreeing to Salus Optima’s',
    term: 'Terms of Use & Privacy Policy',
    tool_tip_1: 'this tool is not intended to diagnose or treat any conditions.',
    tool_tip_2:
      'Before taking any medications or supplements, please check packaging for safety information including warnings\n' +
      '          to those for whom the product may not be suitable.',
    tool_tip_3:
      'If you are pregnant, have a chronic or diagnosed digestive condition, please seek advice from your doctor or\n' +
      '          health care practitioner.',
    tool_tip_4:
      'Information you provide on this page will be submitted to Salus Optima and will be subject to their privacy\n' +
      '          and security policies. The summary of your results will be displayed on Walgreens.com',
  },
  GUT: {
    title: 'Welcome to the Digestive Health Product Selector Tool*',
    subTitle: 'We’re here to help you find products that are best suited to your needs\n',
    agreement: 'by continuing you are agreeing to Salus Optima’s',
    term: 'Terms of Use & Privacy Policy',
    tool_tip_1: 'this tool is not intended to diagnose or treat any conditions.',
    tool_tip_2:
      'Before taking any medications or supplements, please check packaging for safety information including warnings\n' +
      '          to those for whom the product may not be suitable.',
    tool_tip_3:
      'If you are pregnant, have a chronic or diagnosed digestive condition, please seek advice from your doctor or\n' +
      '          health care practitioner.',
    tool_tip_4:
      'Information you provide on this page will be submitted to Salus Optima\n' +
      '          and will be subject to their privacy and security policies. The summary of your results\n' +
      '          will be displayed on Walgreens.com',
  },
  PAIN: {
    title: 'Welcome to the Pain Product Selector Tool*',
    subTitle: 'We’re here to help you find products that are best suited to your needs',
    agreement: 'by continuing you are agreeing to Salus Optima’s',
    term: 'Terms of Use & Privacy Policy',
    tool_tip_1: 'this tool is not intended to diagnose or treat any conditions.',
    tool_tip_2:
      'Before taking any medications or supplements, please check packaging for safety information including warnings\n' +
      '          to those for whom the product may not be suitable.',
    tool_tip_3:
      'If you are pregnant, have a chronic or diagnosed digestive condition, please seek advice from your doctor or\n' +
      '          health care practitioner.',
    tool_tip_4:
      'Information you provide on this page will be submitted to Salus Optima and will be subject to their privacy\n' +
      '          and security policies. The summary of your results will be displayed on Walgreens.com',
  },
  CC: {
    title: 'Welcome to the Cough and Cold Product Selector Tool*',
    subTitle: 'We’re here to help you find products that are best suited to your needs',
    agreement: 'by continuing you are agreeing to Salus Optima’s',
    term: 'Terms of Use & Privacy Policy',
    tool_tip_1: 'this tool is not intended to diagnose or treat any conditions.',
    tool_tip_2:
      'Before taking any medications or supplements, please check packaging for safety information including warnings\n' +
      '          to those for whom the product may not be suitable.',
    tool_tip_3:
      'If you are pregnant, have a chronic or diagnosed digestive condition, please seek advice from your doctor or\n' +
      '          health care practitioner.',
    tool_tip_4:
      'Information you provide on this page will be submitted to Salus Optima and will be subject to their privacy\n' +
      '          and security policies. The summary of your results will be displayed on Walgreens.com',
  },
  ALLERGY: {
    title: 'Welcome to the Allergy Product Selector Tool*',
    subTitle: 'We’re here to help you find products that are best suited to your needs',
    agreement: 'by continuing you are agreeing to Salus Optima’s',
    term: 'Terms of Use & Privacy Policy',
    tool_tip_1: 'this tool is not intended to diagnose or treat any conditions.',
    tool_tip_2:
      'Before taking any medications or supplements, please check packaging for safety information including warnings\n' +
      '          to those for whom the product may not be suitable.',
    tool_tip_3:
      'If you are pregnant, have a chronic or diagnosed digestive condition, please seek advice from your doctor or\n' +
      '          health care practitioner.',
    tool_tip_4:
      'Information you provide on this page will be submitted to Salus Optima and will be subject to their privacy\n' +
      '          and security policies. The summary of your results will be displayed on Walgreens.com',
  },
};
