import { combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { modalReducer, ModalState } from './reducers/modalReducer';
import { recommendationsReducer, RecommendationsState } from './recommendationsStore';
import { userReducer, UserState } from './userStore';
import { quizReducer, QuizState } from './quizStore';

export interface ApplicationState {
  modal: ModalState;
  recommendations: RecommendationsState;
  user: UserState;
  quiz: QuizState;
}

const reducer = combineReducers({
  modal: modalReducer,
  recommendations: recommendationsReducer,
  user: userReducer,
  quiz: quizReducer,
});

export const store = createStore(reducer, composeWithDevTools());
