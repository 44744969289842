import React from 'react';
import classNames from 'classnames';

import './QuizButton.scss';

interface Props {
  title: string;
  isSelected?: boolean;
  onClick(): void;
}

const QuizButton: React.FC<Props> = (props) => {
  const { title, isSelected, onClick } = props;
  const wrapClass = classNames('quiz-button', { 'quiz-button--selected': isSelected });

  return (
    <button className={wrapClass} onClick={onClick}>
      {title}
    </button>
  );
};

export default QuizButton;
