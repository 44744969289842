export enum TAGS {
  MULTI_HIGH_CALCIUM = 'multi_high_calcium',
  MULTI_IRON_B12_ZINC = 'multi_iron_b12_zinc',
  CALCIUM = 'calcium',
  GUMMY = 'gummy',
  TABLET = 'tablet',
  LOW_CALCIUM = 'low_calcium',
  HIGH_CALCIUM = 'high_calcium',
  CALCIUM_VIT_D = 'calcium_vit_d',
  MULTI = 'multi',
  MULTI_AGE = 'multi_age',
  OMEGA3 = 'omega_3',
  LOW_OMEGA = 'low_omega',
  HIGH_OMEGA = 'high_omega',
  LOW_MEAT = 'low_meat',
  LOW_FRUIT_VEG = 'low_fruit_veg',
  HIGH_MEAT = 'high_meat',
  HIGH_FRUIT_VEG = 'high_fruit_veg',
  U50 = 'u50',
  O50 = 'o50',
  IRON = 'iron',
  ZINC = 'zinc',
  PRENATAL = 'prenatal',
  PRENATAL_FORMAT = 'prenatal_format',
  PRENATAL_GUMMY = 'prenatal_gummy',
  PRENATAL_SOFTGEL = 'prenatal_softgel',
  PREGNANT = 'pregnant',
  PREGNANT_GUMMY = 'pregnant_gummy',
  PREGNANT_SOFTGEL = 'pregnant_softgel',
  NOT_PREGNANT = 'not_pregnant',
  MAGNESIUM = 'magnesium',
  ANTIOXIDANT = 'antioxidant',
  MALE = 'male',
  FEMALE = 'female',
  MALE_FEMALE = 'male_female',
  SLEEP = 'sleep',
  JOINT = 'joint',
  HEART = 'heart',
  URINARY = 'urinary',
  WOMENS_HEALTH = 'womens_health',
  MOOD_STRESS = 'mood_stress',
  HAIR_SKIN_NAILS = 'hair_skin_nails',
  VIT_C = 'vit_c',
  VIT_C_DOSE = 'vit_c_dose',
  VIT_C_ONLY = 'vit_c_only',
  VIT_C_PLUS = 'vit_c_plus',
  VIT_D = 'vit_d',
  VIT_D_FORMAT = 'vit_d_format',
  VIT_D_ONLY = 'vit_d_only',
  SOFT_GEL = 'softgel',
  HIGH_DOSE = 'high_dose',
  RDA_DOSE = 'rda_dose',
  NO_SUPPS_USED = 'no_supps_used',
  DIGESTIVE_HEALTH = 'digestive_health',
  IMMUNE = 'immune',
  NO_HEALTH_GOAL = 'no_health_goal',
  SKIN_HAIR_NAILS = 'skin_hair_nails',
  SKIN_HAIR_NAILS_FORMAT = 'skin_hair_nails_format',
  SKIN_HAIR_NAILS_GUMMY = 'skin_hair_nails_gummy',
  SKIN_HAIR_NAILS_TABLET = 'skin_hair_nails_tablet',
  BLOCK_CALCIUM = 'block_calcium',
  BLOCK_IRON = 'block_iron',
  BLOCK_MAGNESIUM = 'block_magnesium',
  BLOCK_OMEGA3 = 'block_omega3',
  BLOCK_VITC = 'block_vitc',
  BLOCK_VITD = 'block_vitd',
  BLOCK_ZINC = 'block_zinc',
}
