import Recommendation from '../modules/Recommendations/models/recommendation';

function SortByLabel(x: Recommendation, y: Recommendation) {
  if (x.label < y.label) {
    return 1;
  }
  if (x.label > y.label) {
    return -1;
  }
  return 0;
}

export default SortByLabel;
