import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore';
import QuizQuestion from '../models/quiz-question';
import { finalQuestion } from '../data/main-questions';
import QuizQuestionOption from '../models/quiz-question-option';
import { CombinedOptionsRules } from '../types/questions';
import { ApplicationState } from '../../../store';
import { UserState } from '../../../store/userStore';
import { firestoreDb } from '../../../App';
import ROUTES from '../../_common/constants/routerConstants';
import { addQuestionToHistory, popQuestionFromHistory, QuizState, setActiveQuestion } from '../../../store/quizStore';
import { questionsList } from '../data/questions-list';
import {
  addExtraTags,
  addProgress,
  addTags,
  popFromProgressHistory,
  removeExtraTags,
  removeTags,
  resetProgress,
} from '../../../store/recommendationsStore';
import { DEVELOPMENT } from '../../config/firebase';

export function useQuestion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quizState = useSelector<ApplicationState, QuizState>((state) => state.quiz);
  const tags = useSelector((state: ApplicationState) => state.recommendations.tags);
  const { activeQuestion: question } = quizState;
  const [questionsQueue, setQuestionsQueue] = useState<QuizQuestion[]>([]);
  const [answersCount, setAnswersCount] = useState(0);
  const [extraQuestionIsShown, setExtraQuestionIsShown] = useState(false);

  // const findNextQuestionId = ()=>{
  //   switch () {
  //
  //   }
  // }
  const getMatchedQuestionId = (items: QuizQuestionOption[], combinedOptionsMap: CombinedOptionsRules) => {
    let matchedQuestionId;

    Object.keys(combinedOptionsMap).forEach((questionId) => {
      const requiredOptions = combinedOptionsMap[questionId];
      const filteredSelectedOptions = items.filter(({ id = '' }) => requiredOptions.includes(id));

      if (filteredSelectedOptions.length === items.length && items.length === requiredOptions.length) {
        matchedQuestionId = questionId;

        filteredSelectedOptions.forEach((item) => {
          dispatch(addTags(item.tags || []));
        });
      }
    });
    return matchedQuestionId;
  };
  const showFinal = (skipExtra: boolean) => {
    if (extraQuestionIsShown || skipExtra) {
      navigate(ROUTES.RECOMMENDATION_PAGE);
    } else {
      dispatch(setActiveQuestion(finalQuestion));
      dispatch(addProgress(100));
    }
  };
  const acceptSelectedOptions = (items: QuizQuestionOption[], combinedOptionsMap: CombinedOptionsRules) => {
    const newQuestionsQueue: QuizQuestion[] = [...questionsQueue];
    let optionsTags: string[] = [];
    let optionsExtraTags: string[] = [];
    let skipExtraQuestion = false;

    if (combinedOptionsMap) {
      const questionId = getMatchedQuestionId(items, combinedOptionsMap);
      const question = questionsList.find((item) => item.id === questionId);

      if (question) {
        newQuestionsQueue.push(question as QuizQuestion);
      }
    } else {
      items.forEach((item) => {
        optionsTags = optionsTags.concat(item.tags || []);
        optionsExtraTags = optionsExtraTags.concat(item.extraTags || []);
        const checkTagsForSkip =
          item.tagsForSkip &&
          item.tagsForSkip.reduce((acc, curr) => {
            if (tags.includes(curr)) {
              return true;
            } else {
              return acc;
            }
          }, false);

        if (item.skipExtra) {
          skipExtraQuestion = true;
        }
        if (item.tagsForSkip && checkTagsForSkip) {
          showFinal(true);
        }

        if (item.subQuestionId) {
          // const questionData = questionsList.find((question) => question.id === item.subQuestionId);
          const questionData = questionsList.find((question) => {
            if (item.avoidTag) {
              const nextId = item.avoidTag.reduce((acc, tag) => {
                const isInclude = tags.includes(tag);
                if (isInclude && item.extraQuestionId) {
                  return item.extraQuestionId;
                } else {
                  return acc;
                }
              }, '');

              return nextId ? question.id === nextId : question.id === item.subQuestionId;
            } else {
              return question.id === item.subQuestionId;
            }
          });

          const isQuestionInQueue = !!newQuestionsQueue.find((question) => question.id === item.subQuestionId);

          if (!isQuestionInQueue) {
            newQuestionsQueue.push(questionData as QuizQuestion);
          }
        }
      });
    }

    dispatch(addTags(optionsTags));
    dispatch(addExtraTags(optionsExtraTags));

    return { newQuestionsQueue, skipExtraQuestion };
  };

  const getNextQuestion = (list: QuizQuestion[], skipExtra: boolean) => {
    if (!list.length) {
      showFinal(skipExtra);
    } else {
      const updatedQuestionsQueue = [...list];
      const nextQuestion = updatedQuestionsQueue.pop();
      const updateAnswersCount = answersCount + 1;

      dispatch(setActiveQuestion(nextQuestion as QuizQuestion));
      setQuestionsQueue(updatedQuestionsQueue);
      setAnswersCount(updateAnswersCount);
      dispatch(addProgress(70 - 70 / updateAnswersCount + 30));
    }
  };

  const handleAnswer = (items: QuizQuestionOption[], combinedOptionsMap: CombinedOptionsRules) => {
    const { newQuestionsQueue: questionsList, skipExtraQuestion } = acceptSelectedOptions(items, combinedOptionsMap);
    getNextQuestion(questionsList, skipExtraQuestion);
  };

  const handleSkip = (data: QuizQuestion) => {
    const { skipQuestionId, skipTags, skipShowRecs } = data;
    if (skipShowRecs) {
      showFinal(true);
    }

    if (skipQuestionId) {
      const nextQuestion = questionsList.find((item) => item.id === skipQuestionId);

      dispatch(setActiveQuestion(nextQuestion as QuizQuestion));
      dispatch(addTags(skipTags || []));

      return;
    }
  };

  const showExtraQuestion = () => {
    // 19 - id of the extra question
    const nextQuestion = questionsList.find((item) => item.id === '19');

    dispatch(setActiveQuestion(nextQuestion as QuizQuestion));
    setExtraQuestionIsShown(true);
  };

  return { question, handleAnswer, handleSkip, showExtraQuestion, extraQuestionIsShown };
}

export function useQuestionStatistic() {
  const userState = useSelector<ApplicationState, UserState>((state) => state.user);
  const { id: userId } = userState;

  const logQuestion = async (questionId: string, options: QuizQuestionOption[]) => {
    const globalWindow = window as any;

    const data = {
      UUID: userId,
      answer: options.map((item) => item.text).join(', '),
      question: questionId,
      timestamp: new Date().toString(),
      query_string_parameter: globalWindow.queryParams || '',
    };

    const answersRef = collection(firestoreDb, DEVELOPMENT.PATH, DEVELOPMENT.SEGMENT as string, DEVELOPMENT.ANSWERS);
    await addDoc(answersRef, data);
  };

  return { logQuestion };
}

export function useQuestionHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quizState = useSelector<ApplicationState, QuizState>((state) => state.quiz);
  const { questionsHistory } = quizState;

  const addQuestion = (data: QuizQuestion) => {
    dispatch(addQuestionToHistory(data));
  };

  const removeSelectedTags = () => {
    const prevQuestion = questionsHistory[questionsHistory.length - 1];

    if (prevQuestion) {
      const questionTags = prevQuestion.options.reduce<string[]>((res, item) => {
        return res.concat(item.tags || []);
      }, []);
      const questionExtraTags = prevQuestion.options.reduce<string[]>((res, item) => {
        return res.concat(item.extraTags || []);
      }, []);

      dispatch(removeTags(questionTags));
      dispatch(removeExtraTags(questionExtraTags));
    }
  };

  const goBack = () => {
    if (!questionsHistory.length) {
      navigate(-1);
      dispatch(resetProgress());
    } else {
      removeSelectedTags();
      dispatch(popQuestionFromHistory());
      dispatch(popFromProgressHistory());
    }
  };

  return { addQuestion, goBack };
}
