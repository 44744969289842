export enum GUT_TAGS {
  TIER_1 = 'tier_1',
  TIER_2 = 'tier_2',
  DIGESTIVE_HEALTH = 'digestive_health',
  DIARRHEA = 'diarrhea',
  ANTACID = 'antacid',
  GAS_BLOATING = 'gas_bloating',
  CONSTIPATION = 'constipation',
  HEMORRHOIDS = 'hemorrhoids',
  LACTOSE_INTOLERANCE = 'lactose_intolerance',
  MOTION_SICKNESS = 'motion_sickness',
  PROBIOTICS = 'probiotics',
  HEMORRHOID_WIPE = 'hemorrhoid_wipe',
  HEMORRHOID_CREAM = 'hemorrhoid_cream',
  NASUEA_BAND = 'nasuea_band',
  MOTION_SICKNESS_MEDS = 'motion_sickness_meds',
  LACTOSE = 'lactose',
  LACTOSE_UNDIAGNOSED = 'lactose_undiagnosed',
  DIARRHEA_MORE_THAN_48_HOURS = 'diarrhea_more_than_48_hours',
  DIARRHEA_RED_FLAG = 'diarrhoea_red_flag',
  DIARRHEA_OTHER_SYMP = 'diarrhoea_other_symp',
  DIARRHEA_SALICYLATE = 'diarrhea_salicylate',
  BISMUTH = 'bismuth',
  LOPERAMIDE_LIQUID = 'loperamide_liquid',
  LOPERAMIDE_CAPSULE = 'loperamide_capsule',
  CONSIPATION_RED_FLAG = 'constipation_red_flag',
  CONSIPATION_LONG_TERM = 'constipation_long_term',
  CONSIPATION_HELP = 'constipation_help',
  MILD_CONSTIPATION = 'mild_constipation',
  LAXATIVE_FORMAT = 'laxative_format',
  FIBRE_LAX_POWDER = 'fibre_lax_powder',
  FIBRE_LAX_CAPSULES = 'fibre_lax_capsules',
  FIBRE_LAX_GUMMY = 'fibre_lax_gummy',
  MILD_LAX = 'mild_lax',
  STRONG_LAX = 'strong_lax',
  FIBRE_FORMAT = 'fibre_format',
  LAX_DAYS = 'lax_days',
  LAX_OVERNIGHT = 'lax_overnight',
  LAX_U55 = 'lax_u55',
  LAX_O55 = 'lax_o55',
  LAX_STRONG_ORAL = 'lax_strong_oral',
  LAX_SUPPOS = 'lax_suppos',
  LAX_ENEMA = 'lax_enema',
  HEARTBURN = 'heartburn',
  HEARTBURN_MORE_THAN_2_WEEKS = 'heartburn_more_than_2_weeks',
  HEARTBURN_OFTEN = 'heartburn_often',
  HEARTBURN_ONLY = 'heartburn_only',
  HEARTBURN_WITH_ASPIRIN = 'heartburn_with_aspirin',
  HEARTBURN_CALC_CARB = 'heartburn_calc_carb',
  HEARTBURN_2_HOURS_PLUS = 'heartburn_2_hours_plus',
  HEARTBURN_MULTI_INGR = 'heartburn_multi_ingr',
  HEARTBURN_FAMOTIDINE = 'heartburn_famotidine',
  GAS_BLOATING_2_WEEKS = 'gas_bloating_2_weeks',
  GAS_BLOATING_CHEW = 'gas_bloating_chew',
  GAS_BLOATING_SOFTGEL = 'gas_bloating_softgel',
  DIGESTIVE_HEALTH_IBS = 'digestive_health_ibs',
  DIGESTIVE_HEALTH_NO_IBS = 'digestive_health_no_ibs',
  DIGESTIVE_HEALTH_FIBRE = 'digestive_health_fibre',
  FIBRE_DIGESTIVE_HEALTH_CAPSULE = 'fibre_digestive_health_capsule',
  FIBRE_DIGESTIVE_HEALTH_GUMMY = 'fibre_digestive_health_gummy',
  FIBRE_DIGESTIVE_HEALTH_POWERED = 'fibre_digestive_health_powder',
  PROBIOTICS_WOMENS_HEALTH = 'probiotic_womens_health',
  PROBIOTICS_ANTIBIOTIC = 'probiotic_antibiotic',
  PROBIOTICS_MOOD_STRESS = 'probiotic_mood_stress',
  PROBIOTICS_SKIN = 'probiotic_skin',
  PROBIOTICS_COGNITIVE = 'probiotic_cognitive',
  PROBIOTICS_GENERAL = 'probiotic_general',
  PROBIOTICS_O50 = 'probiotic_o50',
  PROBIOTICS_MALE = 'probiotics_male',
  PROBIOTICS_FEMALE = 'probiotics_female',
  HEARTBURN_TAKES_ASPIRIN = 'heartburn_takes_aspirin',
  DIGESTIVE_HEALTH_NO_IBS_INFO = 'digestive_health_no_ibs_info',
  DIGESTIVE_HEALTH_NO_IBS_INFORMED = 'digestive_health_no_ibs_informed',
  HEMORRHOID_SUPP = 'hemorrhoid_supp',
  OMEPRAZOLE = 'omeprazole',
  HEARTBURN_WEEKLY = 'heartburn_weekly',
  YEARS_20_29 = '20_29_years',
  YEARS_30_39 = '30_39_years',
  YEARS_40_49 = '40_49_years',
  YEARS_50_59 = '50_59_years',
  OVER_60_YEARS = 'over_60_years',
}
