import React from 'react';
import './ModalCard.scss';
import SVG_ICONS from '../../_common/assets/svg/svg';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { ModalState } from '../../../store/reducers/modalReducer';
import Recommendation from '../../Recommendations/models/recommendation';
import Labels from '../Label/Labels';

const ModalCard = () => {
  const { data } = useSelector<ApplicationState, ModalState>((state) => state.modal);
  const { image, category, title, description, ingredients, warnings, rating = '0', label } = data as Recommendation;
  const dispatch = useDispatch();

  const setModalClose = (): void => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const modalCardProductData = [
    {
      title: 'Description',
      description: description,
    },
    {
      title: 'Ingredients',
      description: ingredients,
    },
    {
      title: 'Warnings',
      description: warnings,
    },
  ];

  return (
    <div className="modal__card-wrapper">
      <div className="modal__transparent-half" onClick={() => setModalClose()} />

      <div className="modal__card-window">
        <div className="modal__close-cross">
          <img
            src={SVG_ICONS.CLOSE_CROSS}
            className="close-cross"
            alt="Button close cross"
            onClick={() => setModalClose()}
          />
        </div>

        <div className="modal__card-content">
          <div className="modal__main-content">
            <div className="modal__primary-content">
              <img src={image} className="modal__card-image" alt="Card image" />
              <div className="primary-content__text">
                <p className="primary-content__title">{title}</p>
                <span className="primary-content__subtitle">To help with your</span>
                <Labels data={label} className="primary-content__label" />
              </div>
            </div>
            <div className="modal__secondary-content">
              {modalCardProductData.map(({ title, description }, index) => {
                return (
                  <div className="description-block" key={index}>
                    <p className="description-block__title">{title}</p>
                    <p className="description-block__description" dangerouslySetInnerHTML={{ __html: description }} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCard;
