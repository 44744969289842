import React from 'react';
import './Stepper.scss';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { RecommendationsState } from '../../../store/recommendationsStore';

const Stepper = () => {
  const recState = useSelector<ApplicationState, RecommendationsState>((state) => state.recommendations);
  const { progress } = recState;

  return (
    <div>
      <div className="progress__bar-wrapper">
        <div className="progress__bar">
          <div className="progress__bar-step" style={{ width: `${progress}%` }} />
        </div>
      </div>
    </div>
  );
};

export default Stepper;
