import leftArrow from './left-arrow.svg';
import salusOptima from './salus_optima.svg';
import symbol from './symbol.svg';
import closeCross from './close-button.svg';
import checkmark from './checkmark.svg';

const SVG_ICONS = {
  LEFT_ARROW: leftArrow,
  OPTIMA: salusOptima,
  SYMBOL: symbol,
  CLOSE_CROSS: closeCross,
  CHECKMARK: checkmark,
};

export default SVG_ICONS;
