import React from 'react';
import './SliderCard.scss';
import Recommendation from '../models/recommendation';
import Checkbox from '../../_common/Checkbox/Checkbox';
import Labels from '../../_common/Label/Labels';

interface SliderCardProps {
  data: Recommendation;
  onChange: (model: string) => void;
  isAlternativesItems?: string;
  setRecommendationItems: (prev: string[]) => void;
  recommendationItems: string[];
  labels?: string;
  onDetailsOpen(): void;
  selectedItems: string[];
}

const SliderCard = (props: SliderCardProps) => {
  const { data, onChange, onDetailsOpen, isAlternativesItems, selectedItems } = props;
  const { id, wic, image, category, title, rating = '0', reviewsCount = 0, why = '', label, model } = data;
  const sortedLabel = label.split(',').sort().join();
  const isChecked = selectedItems.includes(model);

  return (
    <div>
      <div className={'productCard ' + `${isChecked ? 'isChecked' : ''}`}>
        <div className="wrapper">
          <div className="productCard__checkboxes">
            <Checkbox id={model} model={model} onChange={() => onChange(model)} isChecked={isChecked} />
          </div>
          <img src={image} alt="Card image" />
          <p className="productCard__title">{title}</p>
          <span className="productCard__subtitle">To help with your</span>
          <Labels data={sortedLabel} />
        </div>
        <span className="productCard__link" onClick={onDetailsOpen}>
          Learn more
        </span>
      </div>
    </div>
  );
};

export default SliderCard;
