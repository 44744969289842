import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ROUTES from '../constants/routerConstants';
import HomePage from '../../../modules/HomePage/HomePage';
import QuestionPage from '../../../modules/Quiz/pages/QuestionPage/QuestionPage';
import Recommendations from '../../../modules/Recommendations/Recommendations';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path={ROUTES.HOME_PAGE} element={<HomePage />} />
        <Route path={ROUTES.QUIZ_PAGE} element={<QuestionPage />} />
        <Route path={ROUTES.RECOMMENDATION_PAGE} element={<Recommendations />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
