import { USER_ACTIONS } from './actions-types';

export interface UserState {
  id: string;
}

const defaultState: UserState = {
  id: '',
};

export const userReducer = (state: UserState = defaultState, action: any) => {
  switch (action.type) {
    case USER_ACTIONS.SET_ID: {
      return { ...state, id: action.payload };
    }
    default:
      return state;
  }
};

export const setUserId = (id: string) => {
  return {
    type: USER_ACTIONS.SET_ID,
    payload: id,
  };
};
