import { GUT_TAGS } from './gut-tags';
import QuizQuestion from '../models/quiz-question';

export const questionsList = [
  {
    id: '1',
    title:
      'This service provides personalized product options to help provide temporary relief from Digestive conditions',
    description:
      'However, some symptoms or pain, are more serious and require medical attention. If your symptoms are severe ' +
      'or not improving, it’s important to talk with your health care provider for advice and treatment. ',
    subDescription:
      'Before using any product, please check packaging for safety information including warnings to those ' +
      'for whom the product may not be suitable.',
    confirm:
      'If you are pregnant, breastfeeding, or have a chronic condition, please seek advice from your doctor or ' +
      'health care practitioner before using any product.',
    questionNumber: 'gut_nav_s_1',
    options: [
      {
        text: 'I understand',
        subQuestionId: '2',
      },
    ],
  },
  {
    id: '2',
    questionNumber: 'gut_nav_q_37',
    title: 'What is your gender?',
    options: [
      {
        text: 'Female',
        subQuestionId: '3_female',
        tags: [GUT_TAGS.PROBIOTICS_FEMALE], // /*GUT_TAGS.PROBIOTICS_GENERAL*/
      },
      {
        text: 'Male',
        subQuestionId: '3_male',
        tags: [GUT_TAGS.PROBIOTICS_MALE], ///*GUT_TAGS.PROBIOTICS_GENERAL*/
      },
      {
        text: 'Non-binary',
        subQuestionId: '3_male',
        tags: [GUT_TAGS.PROBIOTICS_MALE, GUT_TAGS.PROBIOTICS_FEMALE], ///*GUT_TAGS.PROBIOTICS_GENERAL*/
      },
      {
        text: 'Prefer not to say',
        subQuestionId: '3_male',
        tags: [GUT_TAGS.PROBIOTICS_FEMALE, GUT_TAGS.PROBIOTICS_MALE], ///*GUT_TAGS.PROBIOTICS_GENERAL*/
      },
    ],
  },
  {
    id: '3_female', // GOOD FOR FEMALE
    questionNumber: 'gut_nav_q_41',
    title: 'How old are you?',
    options: [
      {
        text: '20-29 years old',
        subQuestionId: '4_female',
        tags: [GUT_TAGS.YEARS_20_29],
      },
      {
        text: '30-39 years old',
        subQuestionId: '4_female',
        tags: [GUT_TAGS.YEARS_30_39],
      },
      {
        text: '40-49 years old',
        subQuestionId: '4_female',
        tags: [GUT_TAGS.YEARS_40_49],
      },
      {
        text: '50-59 years old',
        subQuestionId: '4a_female',
        tags: [GUT_TAGS.YEARS_50_59], //GUT_TAGS.PROBIOTICS_O50
      },
      {
        text: '60+ years old',
        subQuestionId: '4a_female',
        tags: [GUT_TAGS.OVER_60_YEARS], //GUT_TAGS.PROBIOTICS_O50
      },
    ],
  },
  {
    id: '3_male', // GOOD FOR MALE
    questionNumber: 'gut_nav_q_41',
    title: 'How old are you?',
    options: [
      {
        text: '20-29 years old',
        subQuestionId: '4_male',
        tags: [GUT_TAGS.YEARS_20_29],
      },
      {
        text: '30-39 years old',
        subQuestionId: '4_male',
        tags: [GUT_TAGS.YEARS_30_39],
      },
      {
        text: '40-49 years old',
        subQuestionId: '4_male',
        tags: [GUT_TAGS.YEARS_40_49],
      },
      {
        text: '50-59 years old',
        subQuestionId: '4a_male',
        tags: [GUT_TAGS.YEARS_50_59],
      },
      {
        text: '60+ years old',
        subQuestionId: '4a_male',
        tags: [GUT_TAGS.OVER_60_YEARS],
      },
    ],
  },
  {
    id: '4_male',
    title: 'How would you like to shop for products today?',
    questionNumber: 'gut_nav_q_1',
    options: [
      {
        text: 'Direct me to a specific product (e.g. Tums, Ducolax, Align priobiotic, etc.)',
        subQuestionId: '5_male',
        tags: [GUT_TAGS.TIER_1],
      },
      {
        text: 'Help me find the right product for me',
        subQuestionId: '6',
        tags: [GUT_TAGS.TIER_2],
      },
    ],
  },
  {
    id: '4a_male',
    title: 'How would you like to shop for products today?',
    questionNumber: 'gut_nav_q_1',
    options: [
      {
        text: 'Direct me to a specific product (e.g. Tums, Ducolax, Align priobiotic, etc.)',
        subQuestionId: '5_male',
        tags: [GUT_TAGS.TIER_1],
      },
      {
        text: 'Help me find the right product for me',
        subQuestionId: '6a',
        tags: [GUT_TAGS.TIER_2],
      },
    ],
  },
  {
    id: '4_female',
    title: 'How would you like to shop for products today?',
    questionNumber: 'gut_nav_q_1',
    options: [
      {
        text: 'Direct me to a specific product (e.g. Tums, Ducolax, Align priobiotic, etc.)',
        subQuestionId: '5_female',
        tags: [GUT_TAGS.TIER_1],
      },
      {
        text: 'Help me find the right product for me',
        subQuestionId: '6',
        tags: [GUT_TAGS.TIER_2],
      },
    ],
  },
  {
    id: '4a_female',
    title: 'How would you like to shop for products today?',
    questionNumber: 'gut_nav_q_1',
    options: [
      {
        text: 'Direct me to a specific product (e.g. Tums, Ducolax, Align priobiotic, etc.)',
        subQuestionId: '5_female',
        tags: [GUT_TAGS.TIER_1],
      },
      {
        text: 'Help me find the right product for me',
        subQuestionId: '6a',
        tags: [GUT_TAGS.TIER_2],
      },
    ],
  },
  {
    id: '5_female', // GOOD FOR FEMALE, TIER 1
    questionNumber: 'gut_nav_q_2',
    title: "Great! Can you spot what you're looking for on this list?",
    description: 'Select one of the following.',
    nextText: 'Continue',
    options: [
      {
        text: 'Anti-Diarrheals',
        subQuestionId: '12',
        tags: [GUT_TAGS.DIARRHEA],
      },
      {
        text: 'Antacids',
        subQuestionId: '30',
        tags: [GUT_TAGS.ANTACID],
      },
      {
        text: 'Fiber supplements',
        subQuestionId: '48',
        tags: [GUT_TAGS.DIGESTIVE_HEALTH_FIBRE],
      },
      {
        text: 'Gas relief',
        subQuestionId: '43',
        tags: [GUT_TAGS.GAS_BLOATING],
      },
      {
        text: 'Laxatives',
        subQuestionId: '21a',
        tags: [GUT_TAGS.CONSTIPATION],
      },
      {
        text: 'Hemorrhoid relief',
        subQuestionId: '7',
        tags: [GUT_TAGS.HEMORRHOIDS],
      },
      {
        text: 'Lactose supplements',
        skipExtra: true,
        tags: [GUT_TAGS.LACTOSE],
      },
      {
        text: 'Motion sickness relief',
        subQuestionId: '8',
        tags: [GUT_TAGS.MOTION_SICKNESS],
      },
      {
        text: 'Probiotic supplements',
        subQuestionId: '51_female',
        tags: [GUT_TAGS.PROBIOTICS, GUT_TAGS.PROBIOTICS_GENERAL, GUT_TAGS.PROBIOTICS_O50],
      },
      {
        text: "I'm not sure (in answer list)",
        subQuestionId: '6',
        tags: [GUT_TAGS.PROBIOTICS],
      },
    ],
  },
  {
    id: '5_male', // GOOD FOR MALE, TIER 1
    questionNumber: 'gut_nav_q_2',
    title: "Great! Can you spot what you're looking for on this list?",
    description: 'Select one of the following.',
    nextText: 'Continue',
    options: [
      {
        text: 'Anti-Diarrheals',
        subQuestionId: '12',
        tags: [GUT_TAGS.DIARRHEA],
      },
      {
        text: 'Antacids',
        subQuestionId: '30',
        tags: [GUT_TAGS.ANTACID],
      },
      {
        text: 'Fiber supplements',
        subQuestionId: '48',
        tags: [GUT_TAGS.DIGESTIVE_HEALTH_FIBRE],
      },
      {
        text: 'Gas relief',
        subQuestionId: '43',
        tags: [GUT_TAGS.GAS_BLOATING],
      },
      {
        text: 'Laxatives',
        subQuestionId: '21a',
        tags: [GUT_TAGS.CONSTIPATION],
      },
      {
        text: 'Hemorrhoid relief',
        subQuestionId: '7',
        tags: [GUT_TAGS.HEMORRHOIDS],
      },
      {
        text: 'Lactose supplements',
        skipExtra: true,
        tags: [GUT_TAGS.LACTOSE],
      },
      {
        text: 'Motion sickness relief',
        subQuestionId: '8',
        tags: [GUT_TAGS.MOTION_SICKNESS],
      },
      {
        text: 'Probiotic supplements',
        subQuestionId: '51_male',
        tags: [GUT_TAGS.PROBIOTICS, GUT_TAGS.PROBIOTICS_GENERAL, GUT_TAGS.PROBIOTICS_O50],
      },
      {
        text: "I'm not sure",
        subQuestionId: '6',
        tags: [GUT_TAGS.PROBIOTICS],
      },
    ],
  },
  {
    id: '6', // TIER 2
    questionNumber: 'gut_nav_q_3',
    title: 'No problem, what would you like a product to help with?',
    description: 'Select the group that you are most interested in.',
    options: [
      {
        text: 'Diarrhea',
        subQuestionId: '12',
        tags: [GUT_TAGS.DIARRHEA],
      },
      {
        text: 'Heartburn and/or reflux',
        subQuestionId: '33',
        tags: [GUT_TAGS.HEARTBURN],
      },
      {
        text: 'Gas/bloating',
        subQuestionId: '43',
        tags: [GUT_TAGS.GAS_BLOATING],
      },
      {
        text: 'Constipation',
        subQuestionId: '22',
        tags: [GUT_TAGS.CONSIPATION_HELP],
      },
      {
        text: 'Hemorrhoids',
        subQuestionId: '7',
        tags: [GUT_TAGS.HEMORRHOIDS],
      },
      {
        text: 'Lactose intolerance',
        skipExtra: true,
        tags: [GUT_TAGS.LACTOSE],
      },
      {
        text: 'Motion sickness',
        subQuestionId: '8',
        tags: [GUT_TAGS.MOTION_SICKNESS],
      },
      {
        text: 'Digestive health support (e.g. priobiotics and  fiber)',
        subQuestionId: '46',
        tags: [GUT_TAGS.DIGESTIVE_HEALTH],
      },
    ],
  },
  {
    id: '6a',
    questionNumber: 'gut_nav_q_3',
    title: 'No problem, what would you like a product to help with?',
    description: 'Select the group that you are most interested in.',
    options: [
      {
        text: 'Diarrhea',
        subQuestionId: '12',
        tags: [GUT_TAGS.DIARRHEA],
      },
      {
        text: 'Heartburn and/or reflux',
        subQuestionId: '33',
        tags: [GUT_TAGS.HEARTBURN],
      },
      {
        text: 'Gas/bloating',
        subQuestionId: '43',
        tags: [GUT_TAGS.GAS_BLOATING],
      },
      {
        text: 'Constipation',
        subQuestionId: '22a',
        tags: [GUT_TAGS.CONSIPATION_HELP],
      },
      {
        text: 'Hemorrhoids',
        subQuestionId: '7',
        tags: [GUT_TAGS.HEMORRHOIDS],
      },
      {
        text: 'Lactose intolerance',
        skipExtra: true,
        tags: [GUT_TAGS.LACTOSE],
      },
      {
        text: 'Motion sickness',
        subQuestionId: '8',
        tags: [GUT_TAGS.MOTION_SICKNESS],
      },
      {
        text: 'Digestive health support (e.g. priobiotics and  fiber)',
        subQuestionId: '46a',
        tags: [GUT_TAGS.DIGESTIVE_HEALTH],
      },
    ],
  },
  {
    id: '7',
    questionNumber: 'gut_nav_q_4',
    title: 'Would you like to see wipes, creams or suppositories?',
    options: [
      {
        text: 'Show me wipes',
        skipExtra: true,
        tags: [GUT_TAGS.HEMORRHOID_WIPE],
      },
      {
        text: 'Show me creams',
        skipExtra: true,
        tags: [GUT_TAGS.HEMORRHOID_CREAM],
      },
      {
        text: 'Show me suppositories',
        skipExtra: true,
        tags: [GUT_TAGS.HEMORRHOID_SUPP],
      },
      {
        text: 'Show me all options',
        skipExtra: true,
        tags: [GUT_TAGS.HEMORRHOID_CREAM, GUT_TAGS.HEMORRHOID_WIPE, GUT_TAGS.HEMORRHOID_SUPP],
      },
    ],
  },
  {
    id: '8',
    questionNumber: 'gut_nav_q_5',
    title: 'Which type would you prefer to see?',
    description:
      'Over the counter relief for motion sickness include wrist worn bands that use acupressure to alleviate nausea, and tablets that contain antihistamines.',
    options: [
      {
        text: 'Bands',
        skipExtra: true,
        tags: [GUT_TAGS.NASUEA_BAND],
      },
      {
        text: 'Tablets',
        skipExtra: true,
        tags: [GUT_TAGS.MOTION_SICKNESS_MEDS],
      },
      {
        text: 'Show me both',
        skipExtra: true,
        tags: [GUT_TAGS.MOTION_SICKNESS_MEDS, GUT_TAGS.NASUEA_BAND],
      },
    ],
  },
  {
    id: '12',
    questionNumber: 'gut_nav_s_3',
    title:
      'If you are experiencing severe abdominal pain, fever-like symptoms or have blood in your stool, these symptoms may indicate a serious infection. We strongly advise that you seek advice from your doctor or healthcare provider. ',
    description: 'Please confirm below that you understand this before proceeding.',
    options: [
      {
        text: 'I understand',
        subQuestionId: '13',
        tags: [],
      },
    ],
  },
  {
    id: '13',
    questionNumber: 'gut_nav_q_10',
    title: 'Would you like to see products for diarrhea only, or diarrhea plus other symptoms?',
    description:
      'As well helping to provide relief from symptoms of diarrhea, some products are also designed to support additional symptoms such as heartburn, indigestion, gas, and/or nausea.',
    options: [
      {
        text: 'Diarrhea only',
        subQuestionId: '16',
        tags: [GUT_TAGS.DIARRHEA_SALICYLATE],
      },
      {
        text: 'Diarrhea plus other symptoms',
        subQuestionId: '14',
        tags: [GUT_TAGS.DIARRHEA_OTHER_SYMP],
      },
    ],
  },
  {
    id: '14',
    questionNumber: 'gut_nav_q_11',
    title:
      'Are you currently taking aspirin or other salicylate medication (typically pain relief or anti-inflammatory drugs)?',
    options: [
      {
        text: 'Yes',
        subQuestionId: '15',
        tags: [],
      },
      {
        text: 'No',
        tags: [GUT_TAGS.BISMUTH],
        skipExtra: true,
      },
    ],
  },
  {
    id: '15',
    questionNumber: 'gut_nav_s_4',
    title:
      "As you are already taking medications that may contain salicylate, we'll only provide options for your diarrhea symptoms ",
    description:
      'Remember to seek advice about druug interactions from your doctor or healthcare provider when combining treatments.',
    options: [
      {
        text: 'Show me products for diarrhea only',
        subQuestionId: '16',
        tags: [GUT_TAGS.DIARRHEA_SALICYLATE],
      },
    ],
  },
  {
    id: '16',
    questionNumber: 'gut_nav_q_12',
    title: 'Would you prefer to take a capsule or a liquid?',
    options: [
      {
        text: 'Liquids',
        skipExtra: true,
        tags: [GUT_TAGS.LOPERAMIDE_LIQUID],
      },
      {
        text: 'Capsules',
        skipExtra: true,
        tags: [GUT_TAGS.LOPERAMIDE_CAPSULE],
      },
      {
        text: 'Show me both',
        skipExtra: true,
        tags: [GUT_TAGS.LOPERAMIDE_LIQUID, GUT_TAGS.LOPERAMIDE_CAPSULE],
      },
    ],
  },
  {
    id: '21',
    questionNumber: 'gut_nav_q_15',
    title:
      'We offer a range of different products that have laxative effects. Do you know which type you are looking for?',
    description: 'If not, click "I\'m not sure" and we\'ll help find the right product for you',
    options: [
      {
        text: 'Fiber supplement',
        subQuestionId: '24',
        tags: [GUT_TAGS.FIBRE_FORMAT],
      },
      {
        text: 'Mild overnight laxative (e.g. Dulcolax)',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_OVERNIGHT],
      },
      {
        text: 'Mild laxative (e.g. Miralax - works in 1-3 days)',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_DAYS],
      },
      {
        text: 'Stronger Laxative (e.g. Philips Milk of Magnesia - works in 30 min to 6hrs)',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_STRONG_ORAL],
      },
      {
        text: 'Suppository laxative',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_SUPPOS],
      },
      {
        text: 'Enema',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_ENEMA],
      },
      {
        text: "I'm not sure",
        subQuestionId: '22',
        tags: [GUT_TAGS.CONSIPATION_HELP],
      },
    ],
  },
  {
    id: '21a',
    questionNumber: 'gut_nav_q_15',
    title:
      'We offer a range of different products that have laxative effects. Do you know which type you are looking for?',
    description: 'If not, click "I\'m not sure" and we\'ll help find the right product for you',
    options: [
      {
        text: 'Fiber supplement',
        subQuestionId: '24a',
        tags: [GUT_TAGS.FIBRE_FORMAT],
      },
      {
        text: 'Mild overnight laxative (e.g. Dulcolax)',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_OVERNIGHT],
      },
      {
        text: 'Mild laxative (e.g. Miralax - works in 1-3 days)',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_DAYS],
      },
      {
        text: 'Stronger Laxative (e.g. Philips Milk of Magnesia - works in 30 min to 6hrs)',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_STRONG_ORAL],
      },
      {
        text: 'Suppository laxative',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_SUPPOS],
      },
      {
        text: 'Enema',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_ENEMA],
      },
      {
        text: "I'm not sure",
        subQuestionId: '22a',
        tags: [GUT_TAGS.CONSIPATION_HELP],
      },
    ],
  },
  {
    id: '22',
    questionNumber: 'gut_nav_q_16',
    title: 'On average, how many times per week do you have a bowel movement?',
    description: 'Let us help you find the right product for you.',
    options: [
      {
        text: 'Everyday',
        subQuestionId: '23',
        tags: [GUT_TAGS.MILD_CONSTIPATION],
      },
      {
        text: 'More than 5 times per week',
        subQuestionId: '23',
        tags: [GUT_TAGS.MILD_CONSTIPATION],
      },
      {
        text: 'More than 3 times per week',
        subQuestionId: '23',
        tags: [GUT_TAGS.MILD_CONSTIPATION],
      },
      {
        text: 'Less than 3 times per week',
        subQuestionId: '25',
        tags: [GUT_TAGS.LAXATIVE_FORMAT],
      },
    ],
  },
  {
    id: '22a',
    questionNumber: 'gut_nav_q_16',
    title: 'On average, how many times per week do you have a bowel movement?',
    description: 'Let us help you find the right product for you.',
    options: [
      {
        text: 'Everyday',
        subQuestionId: '23a',
        tags: [GUT_TAGS.MILD_CONSTIPATION],
      },
      {
        text: 'More than 5 times per week',
        subQuestionId: '23a',
        tags: [GUT_TAGS.MILD_CONSTIPATION],
      },
      {
        text: 'More than 3 times per week',
        subQuestionId: '23a',
        tags: [GUT_TAGS.MILD_CONSTIPATION],
      },
      {
        text: 'Less than 3 times per week',
        subQuestionId: '25a',
        tags: [GUT_TAGS.LAXATIVE_FORMAT],
      },
    ],
  },
  {
    id: '23',
    questionNumber: 'gut_nav_q_17',
    title:
      'Constipation is typically seen as having fewer than 3 bowel movements a week. So, while you may not be happy with how often you are going to the toilet, we would suggest that a fiber supplement might be the best place to start',
    options: [
      {
        text: 'Show me fiber supplements',
        subQuestionId: '24',
        tags: [GUT_TAGS.FIBRE_FORMAT],
      },
      {
        text: 'Show me mild laxatives',
        skipExtra: true,
        tags: [GUT_TAGS.MILD_LAX],
      },
    ],
  },
  {
    id: '23a',
    questionNumber: 'gut_nav_q_17',
    title:
      'Constipation is typically seen as having fewer than 3 bowel movements a week. So, while you may not be happy with how often you are going to the toilet, we would suggest that a fiber supplement might be the best place to start',
    options: [
      {
        text: 'Show me fiber supplements',
        subQuestionId: '24a',
        tags: [GUT_TAGS.FIBRE_FORMAT],
      },
      {
        text: 'Show me mild laxatives',
        skipExtra: true,
        tags: [GUT_TAGS.MILD_LAX],
      },
    ],
  },
  {
    id: '24',
    questionNumber: 'gut_nav_q_18',
    title: 'What format would you prefer your fiber supplement in?',
    options: [
      {
        text: 'Powders',
        skipExtra: true,
        tags: [GUT_TAGS.FIBRE_LAX_POWDER, GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_POWERED],
      },
      {
        text: 'Capsules',
        skipExtra: true,
        tags: [GUT_TAGS.FIBRE_LAX_CAPSULES, GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_CAPSULE],
      },
      {
        text: 'Gummies',
        skipExtra: true,
        tags: [GUT_TAGS.FIBRE_LAX_GUMMY, GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_GUMMY],
      },
      {
        text: 'Show me all options',
        skipExtra: true,
        tags: [
          GUT_TAGS.FIBRE_LAX_POWDER,
          GUT_TAGS.FIBRE_LAX_CAPSULES,
          GUT_TAGS.FIBRE_LAX_GUMMY,
          GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_POWERED,
          GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_CAPSULE,
          GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_GUMMY,
        ],
      },
    ],
  },
  {
    id: '24a',
    questionNumber: 'gut_nav_q_18',
    title: 'What format would you prefer your fiber supplement in?',
    options: [
      {
        text: 'Powder',
        skipExtra: true,
        tags: [GUT_TAGS.FIBRE_LAX_POWDER],
      },
      {
        text: 'Capsules',
        skipExtra: true,
        tags: [GUT_TAGS.FIBRE_LAX_CAPSULES],
      },
      {
        text: 'Chewable gummies',
        skipExtra: true,
        tags: [GUT_TAGS.FIBRE_LAX_GUMMY],
      },
      {
        text: 'Show me all options',
        skipExtra: true,
        tags: [GUT_TAGS.FIBRE_LAX_POWDER, GUT_TAGS.FIBRE_LAX_CAPSULES, GUT_TAGS.FIBRE_LAX_GUMMY],
      },
    ],
  },
  {
    id: '25',
    questionNumber: 'gut_nav_q_19',
    title:
      "We suggest trying a fiber supplement or mild laxative first (which usually work in 1-2 days) particularly if you have not previously tried any laxative. If this doesn't provide relief, you can then move to a stronger laxative.",
    options: [
      {
        text: "I'll try a Fiber supplement",
        subQuestionId: '24',
        tags: [GUT_TAGS.FIBRE_FORMAT],
      },
      {
        text: "I'll try a mild laxative",
        skipExtra: true,
        tags: [GUT_TAGS.MILD_LAX],
      },
      {
        text: "I'll try a stronger laxative",
        subQuestionId: '28a', // <<<<<<<<<<<<<<<<<<<<<<<<< if age <50
        tags: [GUT_TAGS.STRONG_LAX],
      },
    ],
  },
  {
    id: '25a',
    questionNumber: 'gut_nav_q_19',
    title:
      'We suggest trying a fiber supplement or mild laxative first (which usually work in 1-2 days) particularly if you have not previously tried any laxative.',
    description: "If this doesn't provide relief, you can then move to a stronger laxative.",
    options: [
      {
        text: "I'll try a Fiber supplement",
        subQuestionId: '24a',
        tags: [GUT_TAGS.FIBRE_FORMAT],
      },
      {
        text: "I'll try a mild laxative",
        skipExtra: true,
        tags: [GUT_TAGS.MILD_LAX],
      },
      {
        text: "I'll try a stronger laxative",
        subQuestionId: '29', // <<<<<<<<<<<<<<<<<<<<<<<<< if age >50
        tags: [GUT_TAGS.STRONG_LAX],
      },
    ],
  },
  {
    id: '28a',
    questionNumber: 'gut_nav_q_22',
    title: 'Would you prefer a product that is taken orally or rectally (suppository or enema)?',
    options: [
      {
        text: 'Oral product (works within 30 min - 6 hours)',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_STRONG_ORAL],
      },
      {
        text: 'Suppository (works within 30 min - 6 hours)',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_SUPPOS],
      },
      {
        text: 'Enema (works within 2-15 minutes)',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_ENEMA],
      },
    ],
  },
  {
    id: '29',
    questionNumber: 'gut_nav_q_23',
    title: 'Would you prefer a product that is taken orally or rectally (i.e. suppository)?',
    options: [
      {
        text: 'Oral product (works within 30 min - 6 hours)',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_STRONG_ORAL],
      },
      {
        text: 'Suppository (works within 15 min - 6 hours)',
        skipExtra: true,
        tags: [GUT_TAGS.LAX_SUPPOS],
      },
    ],
  },
  {
    id: '30',
    questionNumber: 'gut_nav_q_24',
    title: 'We offer a range of different antacid products. Do you know which one you are looking for?',
    description: 'If not, click "I\'m not sure" and we\'ll help you find the right  product for you',
    options: [
      {
        text: 'Tums (Antacids)',
        skipExtra: true,
        tags: [GUT_TAGS.HEARTBURN_CALC_CARB, GUT_TAGS.HEARTBURN_MULTI_INGR],
      },
      {
        text: 'Pepsid (Famotidine)',
        skipExtra: true,
        tags: [GUT_TAGS.HEARTBURN_FAMOTIDINE, GUT_TAGS.HEARTBURN_MULTI_INGR],
      },
      {
        text: 'Prilosec/Nexium (Omeprazole/Esomeprazole)',
        skipExtra: true,
        tags: [GUT_TAGS.OMEPRAZOLE],
      },
      {
        text: 'Pepto-bismol (multi-symptom)',
        skipExtra: true,
        tags: [GUT_TAGS.BISMUTH],
      },
      {
        text: "I'm not sure",
        subQuestionId: '33',
        tags: [GUT_TAGS.HEARTBURN],
      },
    ],
  },
  {
    id: '33',
    questionNumber: 'gut_nav_q_26',
    title: 'How often you are experiencing symptoms of heartburn and/or reflux?',
    options: [
      {
        text: 'Less than once per week',
        subQuestionId: '35',
        tags: [],
      },
      {
        text: '1 day per week',
        subQuestionId: '35',
        tags: [GUT_TAGS.HEARTBURN_WEEKLY],
      },
      {
        text: '2 or more times per week',
        subQuestionId: '35a',
        tags: [GUT_TAGS.HEARTBURN_OFTEN],
      },
    ],
  },
  {
    id: '35',
    questionNumber: 'gut_nav_q_27',
    title:
      'Are you currently taking aspirin or other salicylate medication (typically pain relief or anti-inflammatory drugs)?',
    options: [
      {
        text: 'Yes',
        subQuestionId: '39',
        tags: [GUT_TAGS.HEARTBURN_ONLY, GUT_TAGS.HEARTBURN_TAKES_ASPIRIN],
      },
      {
        text: 'No',
        subQuestionId: '37',
        tags: [GUT_TAGS.HEARTBURN_WITH_ASPIRIN],
      },
    ],
  },
  {
    id: '35a',
    questionNumber: 'gut_nav_q_27',
    title:
      'Are you currently taking aspirin or other salicylate medication (typically painkillers or anti-inflammatory drugs)?',
    options: [
      {
        text: 'Yes',
        subQuestionId: '39a',
        tags: [GUT_TAGS.HEARTBURN_ONLY, GUT_TAGS.HEARTBURN_TAKES_ASPIRIN],
      },
      {
        text: 'No',
        subQuestionId: '37a',
        tags: [GUT_TAGS.HEARTBURN_WITH_ASPIRIN],
      },
    ],
  },
  {
    id: '37',
    questionNumber: 'gut_nav_q_28',
    title: 'Would you like to see products for heartburn only, or heartburn plus other symptoms?',
    description:
      'As well helping to provide relief from symptoms of heartburn, some products are also designed to support additional symptoms such as diarrhea, indigestion, gas, and/or nausea.',
    options: [
      {
        text: 'Heartburn only',
        subQuestionId: '39',
        tags: [GUT_TAGS.HEARTBURN_ONLY],
      },
      {
        text: 'Heartburn plus other symptoms',
        skipExtra: true,
        tags: [GUT_TAGS.BISMUTH],
      },
    ],
  },
  {
    id: '37a',
    questionNumber: 'gut_nav_q_28',
    title: 'Would you like to see products for heartburn only, or heartburn plus other symptoms?',
    description:
      'As well helping to provide relief from symptoms of heartburn, some products are also designed to support additional symptoms such as diarrhea, indigestion, gas, and/or nausea.',
    options: [
      {
        text: 'Heartburn only',
        subQuestionId: '39a',
        tags: [GUT_TAGS.HEARTBURN_ONLY],
      },
      {
        text: 'Heartburn plus other symptoms',
        skipExtra: true,
        tags: [GUT_TAGS.BISMUTH],
      },
    ],
  },
  {
    id: '39',
    questionNumber: 'gut_nav_q_30',
    title: 'How long do your symptoms usually last?',
    options: [
      {
        text: 'Less than 2 hours',
        skipExtra: true,
        tags: [GUT_TAGS.HEARTBURN_CALC_CARB],
      },
      {
        text: 'More than 2 hours',
        subQuestionId: '40',
        tags: [GUT_TAGS.HEARTBURN_2_HOURS_PLUS],
      },
    ],
  },
  {
    id: '39a',
    questionNumber: 'gut_nav_q_30',
    title: 'How long do your symptoms usually last?',
    options: [
      {
        text: 'Less than 2 hours',
        skipExtra: true,
        tags: [GUT_TAGS.HEARTBURN_CALC_CARB],
      },
      {
        text: 'More than 2 hours',
        subQuestionId: '40a',
        tags: [GUT_TAGS.HEARTBURN_2_HOURS_PLUS],
      },
    ],
  },
  {
    id: '40',
    questionNumber: 'gut_nav_q_31',
    title: 'Would you like a preventative or a reactive product (i.e. one to take in response to symptoms)?',
    options: [
      {
        text: 'Show me preventitive products',
        skipExtra: true,
        tags: [GUT_TAGS.HEARTBURN_FAMOTIDINE],
      },
      {
        text: 'Show me reactive products',
        skipExtra: true,
        tags: [GUT_TAGS.HEARTBURN_MULTI_INGR],
      },
    ],
  },
  {
    id: '40a',
    questionNumber: 'gut_nav_q_42',
    title: 'Would you like a preventative or a reactive product (i.e. one to take in response to symptoms)?',
    options: [
      {
        text: 'Show me preventitive products',
        skipExtra: true,
        tags: [GUT_TAGS.HEARTBURN_FAMOTIDINE],
      },
      {
        text: 'Show me reactive products',
        skipExtra: true,
        tags: [GUT_TAGS.HEARTBURN_MULTI_INGR, GUT_TAGS.OMEPRAZOLE],
      },
    ],
  },
  {
    id: '43',
    questionNumber: 'gut_nav_q_33',
    title: 'Would you prefer to see capsule or a chewable tablet products?',
    options: [
      {
        text: 'Capsules',
        skipExtra: true,
        tags: [GUT_TAGS.GAS_BLOATING_SOFTGEL],
      },
      {
        text: 'Chewable tablets',
        skipExtra: true,
        tags: [GUT_TAGS.GAS_BLOATING_CHEW],
      },
      {
        text: 'Show me both',
        skipExtra: true,
        tags: [GUT_TAGS.GAS_BLOATING_CHEW, GUT_TAGS.GAS_BLOATING_SOFTGEL],
      },
    ],
  },
  {
    id: '46',
    questionNumber: 'gut_nav_q_35',
    title: 'Would you like to see probiotics or fiber products?',
    description:
      'Probiotic supplements are beneficial bacteria in your intestines which help support digestive health. ' +
      "Dietary fibre comes from fibres of plants that can't be digested. Some dietary fibres can help " +
      'with constipation and feed the beneficial bacteria in our intestines.',
    options: [
      {
        text: 'Show me probiotics',
        skipExtra: true,
        tags: [GUT_TAGS.PROBIOTICS_GENERAL, GUT_TAGS.PROBIOTICS],
      },
      {
        text: 'Show me fiber products',
        subQuestionId: '48',
        tags: [GUT_TAGS.DIGESTIVE_HEALTH_FIBRE],
      },
      {
        text: 'Show me both',
        skipExtra: true,
        tags: [
          GUT_TAGS.PROBIOTICS_GENERAL,
          GUT_TAGS.PROBIOTICS,
          GUT_TAGS.DIGESTIVE_HEALTH_FIBRE,
          GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_GUMMY,
          GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_CAPSULE,
          GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_POWERED,
        ],
      },
    ],
  },
  {
    id: '46a',
    questionNumber: 'gut_nav_q_35',
    title: 'Would you like to see probiotics or fiber products?',
    description:
      'Probiotic supplements are beneficial bacteria in your intestines which help support digestive health. ' +
      "Dietary fibre comes from fibres of plants that can't be digested. Some dietary fibres can help " +
      'with constipation and feed the beneficial bacteria in our intestines.',
    options: [
      {
        text: 'Show me probiotics',
        skipExtra: true,
        tags: [GUT_TAGS.PROBIOTICS_O50, GUT_TAGS.PROBIOTICS_GENERAL, GUT_TAGS.PROBIOTICS],
      },
      {
        text: 'Show me fiber products',
        subQuestionId: '48',
        tags: [GUT_TAGS.DIGESTIVE_HEALTH_FIBRE],
      },
      {
        text: 'Show me both',
        skipExtra: true,
        tags: [
          GUT_TAGS.PROBIOTICS_O50,
          GUT_TAGS.PROBIOTICS_GENERAL,
          GUT_TAGS.PROBIOTICS,
          GUT_TAGS.DIGESTIVE_HEALTH_FIBRE,
          GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_GUMMY,
          GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_CAPSULE,
          GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_POWERED,
        ],
      },
    ],
  },
  {
    id: '48',
    questionNumber: 'gut_nav_q_36',
    title: 'What format would you prefer your fiber supplement in?',
    options: [
      {
        text: 'Gummies',
        skipExtra: true,
        tags: [GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_GUMMY],
      },
      {
        text: 'Capsules',
        skipExtra: true,
        tags: [GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_CAPSULE],
      },
      {
        text: 'Powder',
        skipExtra: true,
        tags: [GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_POWERED],
      },
      {
        text: 'Show me all options',
        skipExtra: true,
        tags: [
          GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_GUMMY,
          GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_CAPSULE,
          GUT_TAGS.FIBRE_DIGESTIVE_HEALTH_POWERED,
        ],
      },
    ],
  },
  {
    id: '51_male', // GOOD FOR MALE
    questionNumber: 'gut_nav_q_39',
    title: 'Have you recently taken, or are you currently taking antibiotics?',
    options: [
      {
        text: 'Yes',
        subQuestionId: '52_male',
        tags: [GUT_TAGS.PROBIOTICS_ANTIBIOTIC],
      },
      {
        text: 'No',
        subQuestionId: '52_male',
        tags: [],
      },
    ],
  },
  {
    id: '51_female', // GOOD FOR FEMALE
    questionNumber: 'gut_nav_q_39',
    title: 'Have you recently taken, or are you currently taking antibiotics?',
    options: [
      {
        text: 'Yes',
        subQuestionId: '52_female',
        tags: [GUT_TAGS.PROBIOTICS_ANTIBIOTIC],
      },
      {
        text: 'No',
        subQuestionId: '52_female',
        tags: [],
      },
    ],
  },
  {
    id: '52_female', // GOOD FOR FEMALE
    questionNumber: 'gut_nav_q_40',
    title:
      'Would you like to see probiotics containing additive ingredients that may help support other areas of health?',
    options: [
      {
        text: 'Mood and stress support',
        skipExtra: true,
        tags: [GUT_TAGS.PROBIOTICS_MOOD_STRESS],
      },
      {
        text: 'Skin health',
        skipExtra: true,
        tags: [GUT_TAGS.PROBIOTICS_SKIN],
      },
      {
        text: 'Cognitive support',
        skipExtra: true,
        tags: [GUT_TAGS.PROBIOTICS_COGNITIVE],
      },
      {
        text: 'Vaginal health',
        skipExtra: true,
        tags: [GUT_TAGS.PROBIOTICS_WOMENS_HEALTH],
      },
      {
        text: 'No thanks',
        skipExtra: true,
        tags: [GUT_TAGS.PROBIOTICS_GENERAL],
      },
    ],
  },
  {
    id: '52_male', // GOOD FOR MALE
    questionNumber: 'gut_nav_q_44',
    title:
      'Would you like to see probiotics containing additive ingredients that may help support other areas of health?',
    options: [
      {
        text: 'Mood and stress support',
        skipExtra: true,
        tags: [GUT_TAGS.PROBIOTICS_MOOD_STRESS],
      },
      {
        text: 'Skin health',
        skipExtra: true,
        tags: [GUT_TAGS.PROBIOTICS_SKIN],
      },
      {
        text: 'Cognitive support',
        skipExtra: true,
        tags: [GUT_TAGS.PROBIOTICS_COGNITIVE],
      },
      {
        text: 'No thanks',
        skipExtra: true,
        tags: [GUT_TAGS.PROBIOTICS_GENERAL],
      },
    ],
  },
] as QuizQuestion[];
