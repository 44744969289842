import React from 'react';
import './Button.scss';

interface ButtonProps {
  title: string;
  className?: string;
  disabled?: boolean;
  clickHandler?: () => void;
}

const Button = ({ title, disabled, className, clickHandler }: ButtonProps) => {
  const wrapClass = `${className} ${disabled ? 'button-disabled' : ''}`;

  return (
    <button className={wrapClass} disabled={disabled} onClick={clickHandler}>
      {title}
    </button>
  );
};

export default Button;
