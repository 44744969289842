import { QUIZ_ACTIONS } from './actions-types';
import QuizQuestion from '../modules/Quiz/models/quiz-question';
import { questionsList } from '../modules/Quiz/data/questions-list';

export interface QuizState {
  questionsHistory: QuizQuestion[];
  activeQuestion: QuizQuestion;
}

const defaultState: QuizState = {
  questionsHistory: [],
  activeQuestion: questionsList[0],
};

export const quizReducer = (state: QuizState = defaultState, action: any) => {
  switch (action.type) {
    case QUIZ_ACTIONS.SET_QUESTIONS_HISTORY: {
      return { ...state, questionsHistory: action.payload };
    }
    case QUIZ_ACTIONS.ADD_QUESTION_TO_HISTORY: {
      return { ...state, questionsHistory: [...state.questionsHistory, action.payload] };
    }
    case QUIZ_ACTIONS.SET_ACTIVE_QUESTION: {
      return { ...state, activeQuestion: action.payload };
    }
    case QUIZ_ACTIONS.RESET_QUIZ: {
      return { ...state, activeQuestion: questionsList[0], questionsHistory: [] };
    }
    case QUIZ_ACTIONS.POP_QUESTION_FROM_HISTORY: {
      const list = [...state.questionsHistory];

      return { ...state, questionsHistory: list, activeQuestion: list.pop() };
    }
    default:
      return state;
  }
};

export const resetQuiz = () => {
  return {
    type: QUIZ_ACTIONS.RESET_QUIZ,
  };
};

export const addQuestionToHistory = (data: QuizQuestion) => {
  return {
    type: QUIZ_ACTIONS.ADD_QUESTION_TO_HISTORY,
    payload: data,
  };
};

export const popQuestionFromHistory = () => {
  return {
    type: QUIZ_ACTIONS.POP_QUESTION_FROM_HISTORY,
  };
};

export const setActiveQuestion = (data: QuizQuestion) => {
  return {
    type: QUIZ_ACTIONS.SET_ACTIVE_QUESTION,
    payload: data,
  };
};
