import SortByTitle from '../../../utils/sortByTitle';
import SortByLabel from '../../../utils/sortByLabel';
import Recommendation from '../models/recommendation';

export function useSliders() {
  const getLowerSliderData = (listOfRecommendations: Recommendation[], upperSliderData: Recommendation[]) => {
    const allNotUniqueProducts = listOfRecommendations.filter((data: Recommendation) => {
      return upperSliderData.every((item: Recommendation) => item.wic !== data.wic);
    });

    const allNotUniquePlusSlicedDataSorted = allNotUniqueProducts.sort(SortByTitle);
    const notUniqueSoredLabel = allNotUniquePlusSlicedDataSorted.sort(SortByLabel);

    return [...notUniqueSoredLabel];
  };

  const getUpperSliderData = (
    uniqueWalgreensByLabelArr: Recommendation[],
    uniqueNotWalgreensByLabelArr: Recommendation[]
  ) => {
    const allUniqueByLabelWalgreensAndNotWalgreens = [...uniqueNotWalgreensByLabelArr, ...uniqueWalgreensByLabelArr];
    const allUniqueByLabelMap = new Map(
      allUniqueByLabelWalgreensAndNotWalgreens.map((item: Recommendation) => [item.label, item])
    );
    const allUniqueByLabelSortedArr = Array.from(allUniqueByLabelMap.values()).sort(SortByTitle);

    return [...allUniqueByLabelSortedArr].slice(0, 3);
  };

  const getUniqueNotWalgreensSortedProducts = (listOfRecommendations: Recommendation[]) => {
    const notWalgreensSorted = listOfRecommendations
      .filter((data: Recommendation) => {
        return !data.title.includes('Walgreens');
      })
      .sort(SortByTitle);

    const uniqueNotWalgreensByLabel = new Map(notWalgreensSorted.map((item: Recommendation) => [item.label, item]));
    return Array.from(uniqueNotWalgreensByLabel.values());
  };

  const getUniqueWalgreensSortedProducts = (listOfRecommendations: Recommendation[]) => {
    const onlyWalgreensSorted = listOfRecommendations
      .filter((data: Recommendation) => {
        return data.title.includes('Walgreens');
      })
      .sort(SortByTitle);

    const uniqueWalgreensByLabel = new Map(onlyWalgreensSorted.map((item: Recommendation) => [item.label, item]));

    return Array.from(uniqueWalgreensByLabel.values());
  };

  return {
    getUniqueNotWalgreensSortedProducts,
    getUniqueWalgreensSortedProducts,
    getLowerSliderData,
    getUpperSliderData,
  };
}

export default useSliders;
