import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { firestoreDb } from '../../../App';
import { useDispatch, useSelector } from 'react-redux';
import { RecommendationsState, setRecommendations } from '../../../store/recommendationsStore';
import Recommendation from '../models/recommendation';
import { ApplicationState } from '../../../store';
import { UserState } from '../../../store/userStore';
import { DEVELOPMENT } from '../../config/firebase';
import { CART_CODES, WALL_GREENS_CLIENT_KEY_PROD } from '../constants/api';
import { TAGS } from '../../Quiz/data/vits-tags';
import CC_TAGS from '../../Quiz/data/cc-tags';

export function useRecommendationsRequest() {
  const dispatch = useDispatch();
  const recommendationStore = useSelector<ApplicationState, RecommendationsState>((state) => state.recommendations);
  const { tags, extraTags } = recommendationStore;

  const buildLongTags = () => {
    const longTags = [];
    const O50_FEMALE_LOW_FRUIT_VEG =
      tags.includes(TAGS.O50) && tags.includes(TAGS.FEMALE) && tags.includes(TAGS.LOW_FRUIT_VEG);
    const O50_MALE_LOW_FRUIT_VEG =
      tags.includes(TAGS.O50) && tags.includes(TAGS.MALE) && tags.includes(TAGS.LOW_FRUIT_VEG);
    const U50_FEMALE_LOW_FRUIT_VEG =
      tags.includes(TAGS.U50) && tags.includes(TAGS.FEMALE) && tags.includes(TAGS.LOW_FRUIT_VEG);
    const U50_MALE_LOW_FRUIT_VEG =
      tags.includes(TAGS.U50) && tags.includes(TAGS.MALE) && tags.includes(TAGS.LOW_FRUIT_VEG);
    const MALE_LOW_FRUIT_VEG = tags.includes(TAGS.MALE) && tags.includes(TAGS.LOW_FRUIT_VEG);
    const O50_FEMALE_LOW_MULTI = tags.includes(TAGS.O50) && tags.includes(TAGS.FEMALE) && tags.includes(TAGS.MULTI);
    const O50_MALE_LOW_MULTI = tags.includes(TAGS.O50) && tags.includes(TAGS.MALE) && tags.includes(TAGS.MULTI);
    const U50_FEMALE_LOW_MULTI = tags.includes(TAGS.U50) && tags.includes(TAGS.FEMALE) && tags.includes(TAGS.MULTI);
    const U50_MALE_LOW_MULTI = tags.includes(TAGS.U50) && tags.includes(TAGS.MALE) && tags.includes(TAGS.MULTI);
    const HEART = tags.includes(TAGS.HEART);

    // const DRY_COUGH_ONLY = tags.includes(CC_TAGS.COUGH_ONLY) && tags.includes(CC_TAGS.DRY_COUGH);
    // const DRY_COUGH_PLUS = tags.includes(CC_TAGS.COUGH_PLUS) && tags.includes(CC_TAGS.DRY_COUGH);
    // const MUCUS_COUGH_ONLY = tags.includes(CC_TAGS.COUGH_ONLY) && tags.includes(CC_TAGS.MUCUS_COUGH);
    // const MUCUS_COUGH_PLUS = tags.includes(CC_TAGS.COUGH_PLUS) && tags.includes(CC_TAGS.MUCUS_COUGH);
    // const DRY_MUCUS_COUGH_ONLY = tags.includes(CC_TAGS.COUGH_ONLY) && tags.includes(CC_TAGS.DRY_MUCUS_COUGH);
    // const DRY_MUCUS_COUGH_PLUS = tags.includes(CC_TAGS.COUGH_PLUS) && tags.includes(CC_TAGS.DRY_MUCUS_COUGH);

    const DRY_COUGH_ONLY_MED =
      tags.includes(CC_TAGS.MED_OPTIONS) && tags.includes(CC_TAGS.COUGH_ONLY) && tags.includes(CC_TAGS.DRY_COUGH);
    const DRY_COUGH_PLUS_MED =
      tags.includes(CC_TAGS.MED_OPTIONS) && tags.includes(CC_TAGS.COUGH_PLUS) && tags.includes(CC_TAGS.DRY_COUGH);

    const MUCUS_COUGH_ONLY_MED =
      tags.includes(CC_TAGS.MED_OPTIONS) && tags.includes(CC_TAGS.COUGH_ONLY) && tags.includes(CC_TAGS.MUCUS_COUGH);
    const MUCUS_COUGH_PLUS_MED =
      tags.includes(CC_TAGS.MED_OPTIONS) && tags.includes(CC_TAGS.COUGH_PLUS) && tags.includes(CC_TAGS.MUCUS_COUGH);

    //NEED TO CHECK IN FIREBASE, BOTTOM 2 VARIABLES HIGHLIGHTED AS RED IN MIROBOARD  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    const DRY_MUCUS_COUGH_ONLY_MED =
      tags.includes(CC_TAGS.MED_OPTIONS) && tags.includes(CC_TAGS.COUGH_ONLY) && tags.includes(CC_TAGS.DRY_MUCUS_COUGH);
    const DRY_MUCUS_COUGH_PLUS_MED =
      tags.includes(CC_TAGS.MED_OPTIONS) && tags.includes(CC_TAGS.COUGH_PLUS) && tags.includes(CC_TAGS.DRY_MUCUS_COUGH);

    const BLOCKED_NOSE_MED = tags.includes(CC_TAGS.BLOCKED_NOSE) && tags.includes(CC_TAGS.MED_OPTIONS);
    const BLOCKED_NOSE_NONE_MED = tags.includes(CC_TAGS.BLOCKED_NOSE) && tags.includes(CC_TAGS.NON_MED_OPTIONS);

    const DRY_COUGH_PLUS_MED_AVOID_ACETAMINOPHEN =
      tags.includes(CC_TAGS.AVOID_ACETAMINOPHEN) &&
      tags.includes(CC_TAGS.MED_OPTIONS) &&
      tags.includes(CC_TAGS.COUGH_PLUS) &&
      tags.includes(CC_TAGS.DRY_COUGH);
    const DRY_COUGH_PLUS_MED_AVOID_PAIN_RELIEF =
      tags.includes(CC_TAGS.AVOID_PAIN_RELIEF) &&
      tags.includes(CC_TAGS.MED_OPTIONS) &&
      tags.includes(CC_TAGS.COUGH_PLUS) &&
      tags.includes(CC_TAGS.DRY_COUGH);
    const MUCUS_COUGH_PLUS_MED_AVOID_ACETAMINOPHEN =
      tags.includes(CC_TAGS.AVOID_ACETAMINOPHEN) &&
      tags.includes(CC_TAGS.MED_OPTIONS) &&
      tags.includes(CC_TAGS.COUGH_PLUS) &&
      tags.includes(CC_TAGS.MUCUS_COUGH);
    const MUCUS_COUGH_PLUS_MED_AVOID_PAIN_RELIEF =
      tags.includes(CC_TAGS.AVOID_PAIN_RELIEF) &&
      tags.includes(CC_TAGS.MED_OPTIONS) &&
      tags.includes(CC_TAGS.COUGH_PLUS) &&
      tags.includes(CC_TAGS.MUCUS_COUGH);
    const SORE_THROAT_MED = tags.includes(CC_TAGS.SORE_THROAT) && tags.includes(CC_TAGS.MED_OPTIONS);
    const SORE_THROAT_NONE_MED = tags.includes(CC_TAGS.SORE_THROAT) && tags.includes(CC_TAGS.NON_MED_OPTIONS);
    switch (true) {
      case O50_FEMALE_LOW_FRUIT_VEG: {
        longTags.push('o50_female_low_fruit_veg');
        break;
      }
      case O50_MALE_LOW_FRUIT_VEG: {
        longTags.push('o50_male_low_fruit_veg');
        break;
      }
      case U50_FEMALE_LOW_FRUIT_VEG: {
        longTags.push('u50_female_low_fruit_veg');
        break;
      }
      case U50_MALE_LOW_FRUIT_VEG: {
        longTags.push('u50_male_low_fruit_veg');
        break;
      }
      case MALE_LOW_FRUIT_VEG: {
        longTags.push('low_fruit_veg_male');
        break;
      }
      case O50_FEMALE_LOW_MULTI: {
        longTags.push('o50_female_multi');
        break;
      }
      case O50_MALE_LOW_MULTI: {
        longTags.push('o50_male_multi');
        break;
      }
      case U50_FEMALE_LOW_MULTI: {
        longTags.push('u50_female_multi');
        break;
      }
      case U50_MALE_LOW_MULTI: {
        longTags.push('u50_male_multi');
        break;
      }
      case HEART: {
        longTags.push('heart');
        break;
      }
      case DRY_COUGH_ONLY_MED: {
        longTags.push('dry_cough_only_med');
        break;
      }
      case DRY_COUGH_PLUS_MED: {
        longTags.push('dry_cough_plus_med');
        break;
      }
      case MUCUS_COUGH_ONLY_MED: {
        longTags.push('mucus_cough_only_med');
        break;
      }
      case MUCUS_COUGH_PLUS_MED: {
        longTags.push('mucus_cough_plus_med');
        break;
      }
      case DRY_MUCUS_COUGH_ONLY_MED: {
        longTags.push('dry_mucus_cough_only_med');
        break;
      }
      case DRY_MUCUS_COUGH_PLUS_MED: {
        longTags.push('dry_mucus_cough_plus_med');
        break;
      }

      case BLOCKED_NOSE_MED: {
        longTags.push('blocked_nose_med');
        break;
      }
      case DRY_COUGH_PLUS_MED_AVOID_ACETAMINOPHEN: {
        longTags.push('dry_cough_plus_med_avoid_acetaminophen');
        break;
      }
      case DRY_COUGH_PLUS_MED_AVOID_PAIN_RELIEF: {
        longTags.push('dry_cough_plus_med_avoid_pain_relief');
        break;
      }
      case MUCUS_COUGH_PLUS_MED_AVOID_ACETAMINOPHEN: {
        longTags.push('mucus_cough_plus_med_avoid_acetaminophen');
        break;
      }
      case MUCUS_COUGH_PLUS_MED_AVOID_PAIN_RELIEF: {
        longTags.push('mucus_cough_plus_med_avoid_pain_relief');
        break;
      }
      case SORE_THROAT_MED: {
        longTags.push('sore_throat_med');
        break;
      }
      case SORE_THROAT_NONE_MED: {
        longTags.push('sore_throat_nonmed');
        break;
      }
      case BLOCKED_NOSE_NONE_MED: {
        longTags.push('blocked_nose_nonmed');
        break;
      }
    }
    return longTags;
  };

  const loadRecommendations = async () => {
    const productsRef = collection(firestoreDb, DEVELOPMENT.PATH, DEVELOPMENT.SEGMENT as string, DEVELOPMENT.PRODUCTS);
    const longTags = buildLongTags();
    const tagsMatrix = [];
    const requestTags = [...tags, ...extraTags, ...longTags];
    const subArraysCount = Math.ceil(requestTags.length / 10);
    let recsList: any[] = [];

    for (let i = 0; i < subArraysCount; i++) {
      tagsMatrix.push(requestTags.slice(i * 10, (i + 1) * 10));
    }

    for (const item of tagsMatrix) {
      //HOLD - array for hardcode testing
      //const testArr = ['u50', 'pregnant', 'pregnant_softgel', 'pregnant_gummy', 'zinc', 'iron', 'immune', 'multivitamin'];
      // const testArr = [
      //   'u50',
      //   'pregnant',
      //   'pregnant_softgel',
      //   'pregnant_gummy',
      //   'immune',
      //   'u50_male_multi',
      //   'u50_male_low_fruit_veg',
      // ];
      // console.log(item, 'item');

      const q = query(productsRef, where('tags', 'array-contains-any', item));
      const querySnapshot = await getDocs(q);
      const list = querySnapshot.docs.map((doc) => doc.data());
      // console.log(list, 'list');
      const sortedList = list.sort((a, b) => (a.label > b.label ? 1 : -1));
      recsList = recsList.concat(sortedList);
    }

    const filteredRec = recsList.filter((item) => {
      if (Array.isArray(item.untag)) {
        const foundUntag = item.untag.find((untag: string) => tags.includes(untag.trim()));

        return !foundUntag;
      }

      return !tags.includes(item.untag) && item.inStock !== '0';
    });

    dispatch(setRecommendations(filteredRec as Recommendation[]));
  };

  return { loadRecommendations };
}

export function useRecommendationsCart() {
  const userState = useSelector<ApplicationState, UserState>((state) => state.user);
  const { id: userId } = userState;

  const addItemsToFirebase = async (selected: string[], provided: string[]) => {
    const cartRef = collection(firestoreDb, DEVELOPMENT.PATH, DEVELOPMENT.SEGMENT as string, DEVELOPMENT.CART);
    const data = {
      UUID: userId,
      selected: selected,
      provided: provided,
      timestamp: new Date().toString(),
    };
    await addDoc(cartRef, data);
  };

  const getTransferUrlClientId = async (skuId: string, products: any[]) => {
    try {
      const req = await fetch('https://api.salus.eu.ngrok.io/clientId', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientKey: WALL_GREENS_CLIENT_KEY_PROD,
          sku: skuId,
          clientCartId: userId,
          products,
        }),
      });
      const { transferURL: data } = await req.json();
      const { statusCode, transferURL } = data;

      if (statusCode !== CART_CODES.SUCCESS && statusCode !== CART_CODES.PARTIAL_SUCCESS) {
        throw new Error("Suggestions aren't available");
      }

      return transferURL;
    } catch (e: any) {
      alert(e?.message);
      return '';
    }
  };

  const addItemsToWallGreensCart = async (selected: string[]) => {
    const productsData = selected.map((id) => ({ skuId: id, qty: '1', type: 'DL' }));
    return getTransferUrlClientId(productsData[0].skuId, productsData);
  };

  const addToCart = async (selected: string[], provided: string[]) => {
    try {
      await addItemsToFirebase(selected, provided);

      const cartUrl = await addItemsToWallGreensCart(selected);
      const globalWindow = window as any;
      const cartUrlWithBanParam = `${cartUrl}${globalWindow.queryParams}`;
      if (cartUrlWithBanParam) {
        window.open(cartUrlWithBanParam, '_blank');
      }
    } catch (e) {
      console.log(e, 'addToCart');
    }
  };

  return { addToCart };
}
