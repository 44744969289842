import React from 'react';
import './Labels.scss';

interface LabelsProps {
  data: string;
  className?: string;
}

const Labels = ({ data, className = 'label' }: LabelsProps) => {
  const arrLabels = data?.split(',');
  const isMobileView = document.documentElement.clientWidth;

  if (isMobileView <= 991) {
    arrLabels?.splice(3);
  } else {
    arrLabels?.splice(4);
  }

  return (
    <>
      {arrLabels?.map((label: string, index: number) => {
        const labelForTwoItems = index < 2 ? label : '...';
        const labelForThreeItems = index < 3 ? label : '...';

        return (
          <button key={index} className={`label ${className}`}>
            {isMobileView > 991 ? labelForThreeItems : labelForTwoItems}
          </button>
        );
      })}
    </>
  );
};

export default Labels;
