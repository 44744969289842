import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../_common/Button/Button';
import QuizButton from '../QuizButton/QuizButton';
import QuizQuestion from '../../models/quiz-question';
import QuizQuestionOption from '../../models/quiz-question-option';
import { MAIN_QUESTIONS_IDS } from '../../constants/quiz';
import ROUTES from '../../../_common/constants/routerConstants';
import PNG_ICONS from '../../assets/png/png';
import { CombinedOptionsRules } from '../../types/questions';
import { useQuestionHistory, useQuestionStatistic } from '../../hooks/questions-hooks';

import './Question.scss';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { RecommendationsState } from '../../../../store/recommendationsStore';

interface Props {
  data: QuizQuestion;
  onAnswer(options: QuizQuestionOption[], combinedOptionsData?: CombinedOptionsRules): void;
  onSkip(data: QuizQuestion): void;
  onExtraQuestionShow(): void;
}

const Question: React.FC<Props> = (props) => {
  const { data, onAnswer, onSkip, onExtraQuestionShow } = props;
  const navigate = useNavigate();
  const { logQuestion } = useQuestionStatistic();
  const { addQuestion } = useQuestionHistory();
  const recommendationStore = useSelector<ApplicationState, RecommendationsState>((state) => state.recommendations);
  const { tags } = recommendationStore;
  const {
    title,
    description,
    subDescription,
    options,
    multiple,
    id,
    skipText,
    nextText,
    combinedOptionsMap,
    maxSelectedOptions,
    questionNumber,
    confirm,
  } = data;
  const [selectedOptions, setSelectedOptions] = useState<QuizQuestionOption[]>([]);
  const isFinal = id === MAIN_QUESTIONS_IDS.FINAL_QUESTION;
  const showNextButton = multiple;

  const handleAnswer = (options: QuizQuestionOption[]) => {
    onAnswer(options, combinedOptionsMap);
    addQuestion(data);
    //TODO
    logQuestion(questionNumber, options);
  };

  const handleConfirm = () => {
    setSelectedOptions([]);
    handleAnswer(selectedOptions);
  };

  const handleOptionSelect = (option: QuizQuestionOption, isSelected: boolean) => {
    if (isSelected) {
      const filteredItems = selectedOptions.filter((item) => item.value !== option.value);

      setSelectedOptions(filteredItems);

      return;
    }

    if (maxSelectedOptions && maxSelectedOptions <= selectedOptions.length) {
      return;
    }

    setSelectedOptions([...selectedOptions, option]);
  };

  const deprecatedTagsAreSelected = (deprecatedTags: string[]) => {
    let isSelected = false;

    deprecatedTags.forEach((tag) => {
      if (tags.includes(tag)) {
        isSelected = true;
      }
    });

    return isSelected;
  };

  const renderOptions = () => {
    return options.map((item, index) => {
      const { text, hideTags } = item;
      const isSelected = !!selectedOptions.find((option) => option.value === item.value);

      const handleClick = () => {
        if (multiple) {
          handleOptionSelect(item, isSelected);
        } else {
          handleAnswer([item]);
        }
      };

      if (hideTags && deprecatedTagsAreSelected(hideTags)) {
        return null;
      }

      return <QuizButton title={text} key={index} isSelected={isSelected} onClick={handleClick} />;
    });
  };

  const handleSkip = () => {
    onSkip(data);
    setSelectedOptions([]);
  };

  return (
    <div key={id}>
      <p className="quiz__question">{title}</p>
      {description && <p className="quiz_sub_info">{description}</p>}
      {subDescription && <p className="quiz_sub_info">{subDescription}</p>}
      {confirm && <p className="quiz_sub_info">{confirm}</p>}
      <span style={{ marginBottom: '40px', display: 'block' }} />
      <div className="quiz__answers-wrapper">{renderOptions()}</div>
      {isFinal ? (
        <>
          <div className="more__question-buttons__wrapper">
            <Button title="Continue with questions" className="continue__button" clickHandler={onExtraQuestionShow} />
            <Button
              title="View suggestions"
              className="recommendations__button"
              clickHandler={() => navigate(ROUTES.RECOMMENDATION_PAGE)}
            />
          </div>
          <div className="wallgreen__bottle-wrapper">
            <img src={PNG_ICONS.WALLGREEN_BOTTLE} className="wallgreen__bottle-more" alt="Wallgreen bottle" />
          </div>
        </>
      ) : (
        <div className="next__button-wrapper">
          {!!skipText && <Button title={skipText} className="recommendations__button" clickHandler={handleSkip} />}
          {showNextButton && (
            <Button
              title={nextText || 'Continue'}
              disabled={!selectedOptions.length}
              className="quiz-button"
              clickHandler={handleConfirm}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Question;
