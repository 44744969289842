import React from 'react';
import HeaderLogo from '../../../_common/HeaderLogo/HeaderLogo';
import Stepper from '../../../_common/Stepper/Stepper';
import BackArrow from '../../../_common/BackArrow/BackArrow';
import Question from '../../components/Question/Question';
import { useQuestion } from '../../hooks/questions-hooks';
import './QuestionPage.scss';

const QuestionPage = () => {
  const { question, handleAnswer, handleSkip, showExtraQuestion } = useQuestion();

  return (
    <div className="question-page__container">
      <HeaderLogo />
      <Stepper />
      <BackArrow title="Back" />
      <Question data={question} onAnswer={handleAnswer} onSkip={handleSkip} onExtraQuestionShow={showExtraQuestion} />
    </div>
  );
};

export default QuestionPage;
