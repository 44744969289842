import QuizQuestionOption from './quiz-question-option';
import { CombinedOptionsRules } from '../types/questions';

export default class QuizQuestion {
  id: string = '';

  title: string = '';

  description?: string = '';

  subDescription?: string = '';

  confirm?: string = '';

  nextText?: string = '';

  skipText?: string = '';

  skipTags?: string[] = [];

  skipTier?: number = 0;

  skipShowRecs?: boolean = false;

  skipQuestionId?: string = '';

  combinedOptionsMap?: CombinedOptionsRules = {};

  options: QuizQuestionOption[] = [];

  multiple?: boolean = false;

  maxSelectedOptions?: number = 0;

  questionNumber: string = '';
}
