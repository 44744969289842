import walgreensLogo from './walgreens-logo.png';
import modalCalciumPlus from './modal-calcium-plus.png';
import modalMelatonin from './modal-melatonin.png';
import checked from './checked.png';

const PNG_ICONS = {
  LOGO: walgreensLogo,
  MODAL_CALCIUM: modalCalciumPlus,
  MODAL_MELATONIN: modalMelatonin,
  CHECKED: checked,
};

export default PNG_ICONS;
