import React from 'react';
import './Slider.scss';
import { useDispatch } from 'react-redux';
import SliderCard from '../SliderCard/SliderCard';
import Recommendation from '../models/recommendation';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

interface SliderRenderProps {
  data: Recommendation[] | any;
  selectedItems: string[];
  isAlternativesItems?: string;
  setRecommendationItems: (prev: string[]) => void;
  recommendationItems: string[];
  onItemsChange: (prev: string[]) => void;
}

SwiperCore.use([Navigation]);

const SliderRender: React.FC<SliderRenderProps> = (props) => {
  const { data, selectedItems, onItemsChange, isAlternativesItems, setRecommendationItems, recommendationItems } =
    props;
  const dispatch = useDispatch();
  const onChange = (model: string) => {
    if (selectedItems.includes(model)) {
      const updatedList = selectedItems.filter((item) => {
        return item !== model;
      });

      onItemsChange(updatedList);
    } else {
      onItemsChange([...selectedItems, model]);
    }
  };

  const renderSlides = () => {
    return data.map((item: Recommendation, index: number) => {
      const handleOpenDetails = () => {
        window.scrollTo({ top: 0 });
        dispatch({ type: 'OPEN_MODAL', data: item });
      };

      return (
        <SwiperSlide key={index}>
          <SliderCard
            selectedItems={selectedItems}
            key={index}
            data={item}
            onChange={onChange}
            onDetailsOpen={handleOpenDetails}
            setRecommendationItems={setRecommendationItems}
            recommendationItems={recommendationItems}
            isAlternativesItems={isAlternativesItems}
          />
        </SwiperSlide>
      );
    });
  };

  return (
    <div className="recommendations__slider-wrapper">
      <div className="recommendations__card-wrapper">
        <Swiper
          slidesPerView={4}
          spaceBetween={20}
          navigation={data.length > 2}
          keyboard={true}
          breakpoints={{
            375: {
              width: 325,
              slidesPerView: 2,
            },
            1170: {
              width: 1130,
              slidesPerView: 4,
            },
          }}
        >
          {renderSlides()}
        </Swiper>
      </div>
    </div>
  );
};

export default SliderRender;
