import Recommendation from '../modules/Recommendations/models/recommendation';
import { RECOMMENDATIONS_ACTIONS } from './actions-types';

export interface RecommendationsState {
  tags: string[];
  extraTags: string[];
  progress: number;
  progressHistory: number[];
  list: Recommendation[];
}

const defaultState: RecommendationsState = {
  tags: [],
  extraTags: [],
  progress: 0,
  progressHistory: [],
  list: [],
};

export const recommendationsReducer = (state: RecommendationsState = defaultState, action: any) => {
  switch (action.type) {
    case RECOMMENDATIONS_ACTIONS.ADD_TAGS: {
      return { ...state, tags: [...state.tags, ...action.payload] };
    }
    case RECOMMENDATIONS_ACTIONS.SET_TAGS: {
      return { ...state, tags: action.payload };
    }
    case RECOMMENDATIONS_ACTIONS.REMOVE_TAGS: {
      return { ...state, tags: state.tags.filter((item) => !action.payload.includes(item)) };
    }
    case RECOMMENDATIONS_ACTIONS.ADD_EXTRA_TAGS: {
      return { ...state, extraTags: [...state.extraTags, ...action.payload] };
    }
    case RECOMMENDATIONS_ACTIONS.REMOVE_EXTRA_TAGS: {
      return { ...state, extraTags: state.extraTags.filter((item) => !action.payload.includes(item)) };
    }
    case RECOMMENDATIONS_ACTIONS.SET_EXTRA_TAGS: {
      return { ...state, extraTags: action.payload };
    }
    case RECOMMENDATIONS_ACTIONS.SET_PROGRESS: {
      return { ...state, progress: action.payload };
    }
    case RECOMMENDATIONS_ACTIONS.ADD_PROGRESS: {
      return { ...state, progress: action.payload, progressHistory: [...state.progressHistory, action.payload] };
    }
    case RECOMMENDATIONS_ACTIONS.POP_FROM_PROGRESS_HISTORY: {
      const history = [...state.progressHistory];

      history.pop();

      const current = history[history.length - 1] || 0;

      return { ...state, progress: current, progressHistory: history };
    }
    case RECOMMENDATIONS_ACTIONS.RESET_PROGRESS: {
      return { ...state, progress: 0, tags: [], list: [], extraTags: [], progressHistory: [] };
    }
    case RECOMMENDATIONS_ACTIONS.SET_RECOMMENDATIONS: {
      return { ...state, list: action.payload };
    }
    default:
      return state;
  }
};

export const addTags = (items: string[]) => {
  return {
    type: RECOMMENDATIONS_ACTIONS.ADD_TAGS,
    payload: items,
  };
};

export const removeTags = (items: string[]) => {
  return {
    type: RECOMMENDATIONS_ACTIONS.REMOVE_TAGS,
    payload: items,
  };
};

export const setTags = (items: Recommendation[]) => {
  return {
    type: RECOMMENDATIONS_ACTIONS.SET_TAGS,
    payload: items,
  };
};

export const addExtraTags = (items: string[]) => {
  return {
    type: RECOMMENDATIONS_ACTIONS.ADD_EXTRA_TAGS,
    payload: items,
  };
};

export const removeExtraTags = (items: string[]) => {
  return {
    type: RECOMMENDATIONS_ACTIONS.REMOVE_EXTRA_TAGS,
    payload: items,
  };
};

export const setExtraTags = (items: Recommendation[]) => {
  return {
    type: RECOMMENDATIONS_ACTIONS.SET_EXTRA_TAGS,
    payload: items,
  };
};

export const setProgress = (value: number) => {
  return {
    type: RECOMMENDATIONS_ACTIONS.SET_PROGRESS,
    payload: value,
  };
};

export const addProgress = (value: number) => {
  return {
    type: RECOMMENDATIONS_ACTIONS.ADD_PROGRESS,
    payload: value,
  };
};

export const resetProgress = () => {
  return {
    type: RECOMMENDATIONS_ACTIONS.RESET_PROGRESS,
  };
};

export const setRecommendations = (items: Recommendation[]) => {
  return {
    type: RECOMMENDATIONS_ACTIONS.SET_RECOMMENDATIONS,
    payload: items,
  };
};

export const popFromProgressHistory = () => {
  return {
    type: RECOMMENDATIONS_ACTIONS.POP_FROM_PROGRESS_HISTORY,
  };
};
