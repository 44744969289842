import React from 'react';

interface SliderWrapperProps {
  secondaryTitle?: string;
  primaryTitle?: string;
  subtitle?: string;
  className?: string | undefined;
  slider: any;
}

const SliderWrapper = ({
  secondaryTitle,
  primaryTitle,
  subtitle,
  slider,
  className = 'recommendations',
}: SliderWrapperProps) => {
  return (
    <div className={className}>
      {primaryTitle && <h2>{primaryTitle}</h2>}
      {slider.props.data.length && !primaryTitle ? <h3>{secondaryTitle}</h3> : null}
      {subtitle && <p className="recommendations__subtitle">{subtitle}</p>}
      {slider}
    </div>
  );
};

export default SliderWrapper;
