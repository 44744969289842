import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import AppRouter from './modules/_common/router/router';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { setUserId } from './store/userStore';
import './App.css';
import { config } from './modules/config/config';

const firestoreApp = initializeApp(config());
export const firestoreDb = getFirestore(firestoreApp);

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setUserId(uuid()));
  }, []);

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
