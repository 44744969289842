import React from 'react';
import { useNavigate } from 'react-router-dom';
import SVG_ICONS from '../assets/svg/svg';
import './BackArrow.scss';
import { useDispatch } from 'react-redux';
import { resetProgress } from '../../../store/recommendationsStore';
import { useQuestionHistory } from '../../Quiz/hooks/questions-hooks';
import { resetQuiz } from '../../../store/quizStore';

interface BackArrowProps {
  title: string;
  notQuizArrow?: boolean;
}

const BackArrow = ({ title, notQuizArrow }: BackArrowProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { goBack } = useQuestionHistory();

  const handleBack = () => {
    if (notQuizArrow) {
      navigate(-1);
      dispatch(resetProgress());
      dispatch(resetQuiz());
    } else {
      goBack();
    }
  };

  return (
    <div>
      <div className="back__buttons-wrapper" onClick={handleBack}>
        <img src={SVG_ICONS.LEFT_ARROW} className="back__button-arrow" alt="Back button arrow" />
        <p className="back__button-text">{title}</p>
      </div>
    </div>
  );
};

export default BackArrow;
