import React from 'react';
import './HomePage.scss';
import PNG_ICONS from './assets/png/png';
import HeaderLogo from '../_common/HeaderLogo/HeaderLogo';
import Button from '../_common/Button/Button';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../_common/constants/routerConstants';
import { contentData } from './data/content-data';

const HomePage = () => {
  const navigate = useNavigate();
  const currentContent = process.env.REACT_APP_CONTENT as string;

  const handleStart = () => {
    navigate(ROUTES.QUIZ_PAGE);
  };

  return (
    <div className="home-page__container">
      <HeaderLogo />
      <div className="wrapper">
        <h1>{contentData[currentContent].title}</h1>
        <p className="home__page-description">{contentData[currentContent].subTitle}</p>
        <img src={PNG_ICONS.WALLGREEN_BOTTLE} className="wallgreen__bottle-img" alt="Wallgreen bottle" />
        <Button title="Take a quiz" className="quiz__button" clickHandler={handleStart} />
        <p className="home__page-agreement">
          {contentData[currentContent].agreement}
          <a target="_blank" rel="noreferrer" href={require('./assets/pdf/SalusOptima_TPP.pdf')}>
            <span>{contentData[currentContent].term}</span>
          </a>
        </p>
      </div>
      <div className="tool__tip-wrapper">
        <p className="tool__tip-text">
          <strong>*Note</strong>
          {contentData[currentContent].tool_tip_1}
        </p>
        <p className="tool__tip-text">{contentData[currentContent].tool_tip_2}</p>
        <p className="tool__tip-text">{contentData[currentContent].tool_tip_3}</p>
        <p className="tool__tip-text">{contentData[currentContent].tool_tip_4}</p>
      </div>
    </div>
  );
};

export default HomePage;
