import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import HeaderLogo from '../_common/HeaderLogo/HeaderLogo';
import BackArrow from '../_common/BackArrow/BackArrow';
import Button from '../_common/Button/Button';
import SliderRender from './Slider/Slider';
import ModalCard from '../_common/ModalCard/ModalCard';
import { ModalState } from '../../store/reducers/modalReducer';
import { ApplicationState } from '../../store';
import { RecommendationsState } from '../../store/recommendationsStore';
import { useRecommendationsCart, useRecommendationsRequest } from './hooks/recommendations-hooks';
import './Recommendations.scss';
import SliderWrapper from './SliderWrapper/SliderWrapper';
import useSliders from './hooks/useSliders';

const Recommendations = () => {
  const modalState = useSelector<ApplicationState, ModalState>((state) => state.modal);
  const {
    getUniqueWalgreensSortedProducts,
    getUniqueNotWalgreensSortedProducts,
    getUpperSliderData,
    getLowerSliderData,
  } = useSliders();

  const { list } = useSelector<ApplicationState, RecommendationsState>((state) => state.recommendations);
  const uniqueWalgreensByLabelArr = getUniqueWalgreensSortedProducts(list);
  const uniqueNotWalgreensByLabelArr = getUniqueNotWalgreensSortedProducts(list);
  const upperSliderData = getUpperSliderData(uniqueWalgreensByLabelArr, uniqueNotWalgreensByLabelArr);
  const lowerSliderData = getLowerSliderData(list, upperSliderData);
  const upperSliderSkuIds = useMemo(() => {
    return upperSliderData.map((product) => product.model);
  }, [upperSliderData]);
  const [selectedItems, setSelectedItems] = useState<string[]>(upperSliderSkuIds);

  console.log('selectedItems', selectedItems);
  const [recommendationItems, setRecommendationItems] = useState<string[]>([]);
  const { addToCart } = useRecommendationsCart();
  const { loadRecommendations } = useRecommendationsRequest();
  const { isOpen } = modalState;
  const selectedItemsLength = selectedItems.length;
  const isAlternativesItems = 'isAlternativesItems';

  const getListOfWic = list.map(({ model }) => model);
  const providedItems = getListOfWic.filter((item, index) => {
    return getListOfWic.indexOf(item) === index;
  });

  const handleCartAdd = () => {
    addToCart(selectedItems, providedItems);
  };

  useEffect(() => {
    loadRecommendations();
  }, []);

  useEffect(() => {
    setSelectedItems(upperSliderSkuIds);
  }, [list]);

  const upperSlider = useMemo(() => {
    return (
      <SliderRender
        data={upperSliderData}
        selectedItems={selectedItems}
        onItemsChange={setSelectedItems}
        setRecommendationItems={setRecommendationItems}
        recommendationItems={recommendationItems}
      />
    );
  }, [list, selectedItems]);
  const lowerSlider = useMemo(() => {
    return (
      <SliderRender
        data={lowerSliderData}
        selectedItems={selectedItems}
        onItemsChange={setSelectedItems}
        isAlternativesItems={isAlternativesItems}
        setRecommendationItems={setRecommendationItems}
        recommendationItems={recommendationItems}
      />
    );
  }, [list, selectedItems]);

  return (
    <>
      <div className="recommendations-page__container">
        {isOpen && <ModalCard />}
        <HeaderLogo />
        <BackArrow title="Retake Quiz" notQuizArrow />
        <div className="sliders-wrapper">
          <SliderWrapper
            slider={upperSlider}
            primaryTitle="Here are some options for you to choose from"
            subtitle="Please see below some options based on what you told us during the quiz. Before using any product, please check packaging for safety information including warnings to those for whom the product may not be suitable"
          />
          <SliderWrapper
            slider={lowerSlider}
            secondaryTitle="Alternatives you may like"
            className={lowerSliderData.length ? 'alternatives' : 'alternatives display-none'}
          />
        </div>
      </div>
      <div className="btn-wrapper">
        {selectedItems.length === selectedItemsLength && <Button title="Add to cart" clickHandler={handleCartAdd} />}
      </div>
    </>
  );
};

export default Recommendations;
