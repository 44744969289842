import Recommendation from '../modules/Recommendations/models/recommendation';

function SortByTitle(x: Recommendation, y: Recommendation) {
  if (x.title < y.title) {
    return 1;
  }
  if (x.title > y.title) {
    return -1;
  }
  return 0;
}

export default SortByTitle;
