export interface ModalState {
  isOpen: boolean;
  data: any;
}

export const defaultState: ModalState = {
  isOpen: false,
  data: {},
};

export interface Action {
  type: string;
  data: any;
}

export const modalReducer = (state: ModalState = defaultState, action: Action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { data: action.data, isOpen: true };
    case 'CLOSE_MODAL':
      return defaultState;
    default:
      return state;
  }
};
