import React from 'react';
import './HeaderLogo.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RecommendationsState, resetProgress } from '../../../store/recommendationsStore';
import { ApplicationState } from '../../../store';
import { resetQuiz } from '../../../store/quizStore';
import { Link } from 'react-router-dom';
import ROUTES from '../constants/routerConstants';
import SVG_ICONS from '../assets/svg/svg';
import PNG_ICONS from '../assets/png/png';

const HeaderLogo = () => {
  const dispatch = useDispatch();
  const recsState = useSelector<ApplicationState, RecommendationsState>((state) => state.recommendations);
  const { tags, extraTags } = recsState;

  const handleHomeRedirect = () => {
    dispatch(resetProgress());
    dispatch(resetQuiz());
  };

  return (
    <>
      <div className="full__logo-wrapper">
        <Link to={ROUTES.HOME_PAGE} className="header__logo-link" onClick={handleHomeRedirect}>
          <img src={PNG_ICONS.LOGO} className="header__logo-img" alt="Company logo" />
          <div className="logo__text-wrapper">
            <p className="logo__text-powered">powered by</p>
            <img src={SVG_ICONS.SYMBOL} className="logo__text-symbol" alt="Logo color symbol" />
            <img src={SVG_ICONS.OPTIMA} className="logo__text-optima" alt="Text salus optima" />
          </div>
        </Link>
      </div>
      {/*<div style={{ position: 'fixed', left: 0, top: 0 }}>*/}
      {/*  <div style={{ whiteSpace: 'nowrap' }}>Tier 1/2 tags: {tags.join(', ')}</div>*/}
      {/*  <br />*/}
      {/*  <div style={{ whiteSpace: 'nowrap' }}>Tier 3 tags: {extraTags.join(', ')}</div>*/}
      {/*</div>*/}
    </>
  );
};

export default HeaderLogo;
