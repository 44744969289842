import React from 'react';
import './Checkbox.scss';

export interface CheckboxProps {
  label?: string;
  id: string;
  onChange: (id: string) => void;
  isChecked?: boolean;
  model: string;
}

const Checkbox = ({ label, id, model, onChange, isChecked }: CheckboxProps) => {
  const handleChange = () => {
    onChange(model);
  };

  return (
    <>
      <input id={id} type="checkbox" checked={isChecked} onChange={handleChange} className="custom-checkbox" />
      <label htmlFor={id}>{label}</label>
    </>
  );
};

export default Checkbox;
